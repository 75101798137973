<template>
  <div ui-view="mainContent">
    <div class="col-sm-12">
      <div
        class="grid-content bg-purplLeave Loadinge content-people"
      >
        <div class="support-index list-people" v-show="!$root.$refs.BaseLayout.isEmployee">
          <div v-for="item in listEmployees" :key="item.id" class="item-people">
            <div class="col-md-12 col-lg-12 ng-scope">
              <div
                class="support-ticket people"
                v-bind:class="
                  currentEmployee.id == item.id ? 'people-acti' : 'people'
                "
              >
                <div @click="onClickItemPeople(item)">
                  <div class="avata">
                    
                    <avatar
                      v-bind:background-color="
                        item.gender == 'Male'
                          ? '#3F51B5'
                          : item.gender == 'FeMale'
                          ? 'rgb(255, 64, 129)'
                          : 'rgb(255,193,7)'
                      "
                      size="52"
                      :username="utils.getUserName(item)"
                      style="margin: auto"
                    ></avatar>
                    <div class="extra-info status-people">
                      <div
                        v-bind:class="
                          item.active == 'ACTIVE'
                            ? 'green'
                            : item.active == 'PENDING'
                            ? 'yellow'
                            : item.active == 'INACTIVE'
                            ? 'red'
                            : 'black'
                        "
                        class="status-pill"
                        style="width: 10px; height: 10px"
                      ></div>
                    </div>
                  </div>
                  <div
                    class="ticket-content"
                    style="max-width: 100%; margin-top: 5px"
                  >
                    <h6 class="ticket-title">
                      {{ item.firstName + " " + item.lastName }}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="content-people">
        <div
          class="col-lg-12 text-right"
          style="padding-right: 0px; margin-bottom: 10px"
        >
          <button
            class="btn btn-primary"
            type="button"
            v-if="!$root.$refs.BaseLayout.isEmployee"
            v-on:click="
              listPolicy.updateEmployee ? updateEmployee() : gotoPayment()
            "
            ng-disabled="loading"
          >
            <span
              class="spinner-grow spinner-grow-sm ng-hide"
              ng-show="loading"
            ></span>
            Save
          </button>
        </div>
        <el-tabs type="border-card" v-model="styleTab" @tab-click="handleClick">
          <el-tab-pane label="Profile" name="Profile">
            <!-- Form Profile -->
            <div
              v-show="this.styleTab == 'Profile'"
              name="ProfileForm"
              class="text-left ng-pristine ng-valid ng-valid-required ng-valid-email">
              <div class="row">
                <div class="col-sm-auto">
                  <h5 style="margin-top: 10px">Personal</h5>
                </div>
                <div
                  class="col-sm-4"
                  v-if="!$root.$refs.BaseLayout.isEmployee">
                  <div v-if="!activeEmployee">
                    <button
                      class="btn ng-binding ng-scope btn-success"
                      @click="
                        listPolicy.updateEmployee
                          ? checkActiveEmployee()
                          : gotoPayment()
                      "
                      style="margin: 10px 0 10px 0"
                    >
                      Activate
                    </button>
                    <button
                      class="btn btn-danger ng-scope"
                      style="margin-right: 20px"
                      v-on:click="
                        listPolicy.deleteEmployee
                          ? showPopupDelete()
                          : gotoPayment()
                      "
                    >
                      Delete Employee <i class="icon-feather-trash-2"></i>
                    </button>
                  </div>
                  <div v-else class="col-sm-4">
                    <button
                      class="btn ng-binding ng-scope btn-danger"
                      @click="
                        listPolicy.updateEmployee
                          ? activeEmployee
                            ? (activeEmployee = false)
                            : (activeEmployee = true)
                          : gotoPayment()
                      "
                      style="margin: 10px 0 10px 0">
                      Deactivate
                    </button>
                  </div>
                </div>
              </div>
              <div class="DivToBeShownToEmployee" v-if="$root.$refs.BaseLayout.isEmployee">
                <div class="row">
                  <div class="col-sm-3">
                    <label class="txt-title-input">First Name</label>
                    <div class="form-group" style="height: 10px">
                      {{ firstName }}
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <label class="txt-title-input">Last Name</label>
                    <div class="form-group" style="height: 10px">
                      {{ lastName }}
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <label class="txt-title-input">Gender</label>
                    <div class="form-group" style="height: 10px">
                      {{ gender }}
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <label class="txt-title-input">Date of Birth</label>
                    <div class="form-group" style="height: 10px">
                      {{ computedDateOfBirthDateFormatted }}
                    </div>
                  </div>
                </div>
    
                <br />
                <h5>Contact details</h5>
                <div class="row">
                  <div class="col-sm-4">
                    <label><b>Address</b></label>
                    <div class="form-group" style="height: 10px">
                      {{ address }}
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <label class="txt-title-input">Email</label>
                    <div class="form-group" style="height: 10px">
                      {{ email }}
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <label class="txt-title-input">Contact Number</label>
                    <div class="form-group" style="height: 10px">
                      {{ contactNumber }}
                    </div>
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-sm-6">
                    <label class="txt-title-input">Notes</label>
                    <div class="form-group" style="height: 10px">
                      {{ notes }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="DivNotToBeShownToEmployee">
                
                <div class="row" style="margin-top: 10px">
                  <div class="col-sm-3">
                    <v-text-field
                      v-model="firstName"
                      name="firstName"
                      class="ng-first-name"
                      placeholder="Kiri"
                      label="First Name"
                      type="text"
                      maxlength="40"
                      :rules="[rules.required, rules.nameRules]"
                      required
                      outlined
                      color="success"
                    ></v-text-field>
                  </div>
                  <div class="col-sm-3">
                    <v-text-field
                      v-model="lastName"
                      name="lastName"
                      class="ng-last-name"
                      placeholder="Tsitouridis"
                      label="Last Name"
                      type="text"
                      maxlength="40"
                      :rules="[rules.required, rules.nameRules]"
                      required
                      outlined
                      color="success"
                    ></v-text-field>
                  </div>
                  <div class="col-sm-3">
                    <v-select
                      :attach="true"
                      v-model="gender"
                      @change="changeInfoEmployee('gender', gender)"
                      :items="listGender"
                      label="Gender"
                      required
                      outlined
                      color="success"
                    ></v-select>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                    <v-menu
                        v-model="taxBirthDateMenu"
                        :close-on-content-click="false"
                        max-width="290">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :value="computedDateOfBirthDateFormatted"
                            name="birthDate.date"
                            label="Date of Birth"
                            class="ng-birth-date"
                            v-bind="attrs"
                            v-on="on"
                            @click:clear="birthDate = null"
                            :rules="[rules.required,rules.dateRules]"
                            required
                            outlined
                            color="success"
                            append-icon="mdi-calendar"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="birthDate"
                          @change="taxBirthDateMenu = false"
                          no-title
                        ></v-date-picker>
                      </v-menu>
                  </div>
                  </div>
                </div>
                <h5>Contact details</h5>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group outlined">
                      <label class="valid" for="">Address</label>
                      <vue-google-autocomplete
                        v-on:keyup="keyupInput('address')"
                        v-on:blur="blurInput('address')"
                        v-on:focus="focusInput('address')"
                        type="text"
                        v-bind:class="
                          isCheckFindMyAddress ? 'disabled-address' : ''
                        "
                        country="au"
                        types="address"
                        id="address"
                        v-on:placechanged="getAddressData"
                        ref="googlemap"
                        v-model="address"
                        class="form-control ng-address isNotNull"
                      ></vue-google-autocomplete>
                    </div>
                  </div>
                </div>
                <div class="form-check" style="margin-bottom: 40px">
                  <input
                    v-model="isCheckFindMyAddress"
                    class="
                      form-check-input
                      ng-pristine ng-untouched ng-valid ng-empty
                    "
                    type="checkbox"
                    value=""
                    id="isCheckFindMyAddress"
                    ng-model="companyData.le.lsl"
                  />
                  <label class="form-check-label" for="isCheckFindMyAddress">
                    I am unable to find my address 
                  </label>
                </div>
                <div v-show="isCheckFindMyAddress">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <v-text-field
                          v-model="address1"
                          name="address1"
                          class="ng-address1"
                          placeholder="1 Shoreline Dr"
                          label="Address 1"
                          type="text"
                          maxlength="40"
                          :rules="[rules.required]"
                          required
                          outlined
                          color="success"
                        ></v-text-field>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group outlined">
                        <label class="valid" for="">Address 2</label>
                        
                        <vue-google-autocomplete
                          v-model="address2"
                          v-on:keyup="keyupInput('address2')"
                          v-on:blur="blurInput('address2')"
                          v-on:focus="focusInput('address2')"
                          placeholder="1 Shoreline Dr, Rhodes, NSW"
                          id="address2"
                          
                          ref="googlemap2"
                          class="
                            form-control
                            ng-pristine ng-untouched ng-valid ng-empty
                          "
                          name="address2"
                          type="text"
                        ></vue-google-autocomplete>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="form-group">
                        <v-text-field
                          v-model="suburb"
                          name="suburb"
                          class="ng-suburb"
                          placeholder="Rhodes"
                          label="Suburb"
                          type="text"
                          maxlength="40"
                          :rules="[rules.required]"
                          required
                          outlined
                          color="success"
                        ></v-text-field>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <v-text-field
                          v-model="postcode"
                          name="postcode"
                          class="ng-post-code"
                          placeholder="2138"
                          label="Postcode"
                          type="text"
                          maxlength="40"
                          :rules="[rules.required]"
                          required
                          outlined
                          color="success"
                        ></v-text-field>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <v-select
                          :attach="true"
                          v-bind:disabled="countryCode != 'au'"
                          class="ng-state"
                          v-model="state"
                          @change="changeInfoEmployee('state', state)"
                          :items="listState"
                          item-text="text"
                          item-value="value"
                          label="State"
                          :rules="[rules.required]"
                          required
                          outlined
                          color="success"
                        ></v-select>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group outlined">
                        <label class="valid" style="z-index: 1" for="">
                          Country</label
                        >
                        <country-flag
                          :country="countryCode"
                          size="normal"
                          class="flag-input"
                        />
                        <el-autocomplete
                          @focus="clickAutocomplete()"
                          @blur="getBackAutocompete()"
                          v-model="country"
                          name="country"
                          class="inline-input form-control"
                          style="width: 100%; !important;"
                          :fetch-suggestions="querySearch"
                          placeholder="Please Input"
                          @select="changeLanguage"
                        >
                          <template slot-scope="{ item }">
                            <div style="display: flex; width: max-content">
                              <country-flag
                                :country="item.value"
                                size="normal"
                              />
                              <div class="value">{{ item.text }}</div>
                            </div>
                          </template>
                        </el-autocomplete>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <v-text-field
                        v-model="email"
                        v-bind:disabled="$root.$refs.BaseLayout.isEmployee"
                        name="email"
                        class="ng-email"
                        placeholder="someone@company.com"
                        label="Email address"
                        type="email"
                        maxlength="40"
                        outlined
                        color="success"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <v-text-field
                        v-model="contactNumber"
                        name="contactNumber"
                        class="ng-phone"
                        placeholder="start with 0 or +"
                        label="Contact number"
                        type="phone"
                        maxlength="40"
                        :rules="[rules.isPhone]"
                        required
                        outlined
                        color="success"
                        @input="formatPhoneNumber"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <h5>Notes</h5>
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <textarea
                        v-model="notes"
                        name="notes"
                        class="form-control"
                        rows="3"
                        placeholder="Use this space to keep track of any extra information about the employee. For example, leave dates, emergency contact details or skills and qualifications."
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>

          <el-tab-pane label="Employment" name="Employment">
           
            <!-- Employment -->
            <div class="DivToBeShownToEmployee" v-if="$root.$refs.BaseLayout.isEmployee">
              <div class="row">
                <div class="col-sm-4">
                  <label class="txt-title-input">Job Title</label>
                  <div class="form-group" style="height: 10px">
                    {{ job }}
                  </div>
                </div>
                <div class="col-sm-4">
                  <label class="txt-title-input">Employment Type</label>
                  <div class="form-group" style="height: 10px">
                    {{ employmentType }}
                  </div>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-sm-4">
                  <label class="txt-title-input">Start Date</label>
                  <div class="form-group" style="height: 10px">
                    {{ computedStartDateFormatted }}
                  </div>
                </div>
                <div class="col-sm-4">
                  <label class="txt-title-input">Termination Date</label>
                  <div class="form-group" style="height: 10px">
                    {{ computedTerminationDateFormatted }}
                  </div>
                </div>
                <div class="col-sm-4">
                  <label class="txt-title-input">Termination Amount</label>
                  <div class="form-group" style="height: 10px">
                    {{ processTerminationAmount>0 ? processTerminationAmount.toFixed(2):terminationAmount }}
                  </div>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-sm-4">
                  <label class="txt-title-input">Weekly Work Hours</label>
                  <div class="form-group" style="height: 10px">
                    {{ weeklyWorkHours }}
                  </div>
                </div>
                <div class="col-sm-4">
                  <div v-if="employmentType != 'Casual'">
                    <label class="txt-title-input">Leave Loading (%)</label>
                    <div class="form-group" style="height: 10px">
                      {{ leaveLoading }}
                    </div>
                  </div>
                  <div v-else>
                    <label class="txt-title-input">Casual Loading (%)</label>
                    <div class="form-group" style="height: 10px">
                      {{ casualLoading }}
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <label class="txt-title-input">Pay Period</label>
                  <div class="form-group" style="height: 10px">
                    {{ payPeriod }}
                  </div>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-sm-4">
                  <label class="txt-title-input">Pay Rate</label>
                  <div class="form-group" style="height: 10px">
                    ${{ payRate }}
                  </div>
                </div>
                <div class="col-sm-4">
                  <label class="txt-title-input">Type Rate</label>
                  <div class="form-group" style="height: 10px">
                    {{ typeRate }}
                  </div>
                </div>
                <div class="col-sm-4">
                  <label class="txt-title-input">Payment Method</label>
                  <div class="form-group" style="height: 10px">
                    {{ paymentMethod }}
                  </div>
                </div>
              </div>
              <br />
            </div>
            <div v-else class="DivNotToBeShownToEmployee">
              <div
                v-show="this.styleTab == 'Employment'"
                class="text-left"
                ng-show="currentTab == 'employment'"
                style=""
              >
                <div class="row">
                  <div class="col-sm-auto">
                    <h5 style="margin-top: 10px">Employment</h5>
                  </div>
                    <div  class="col-sm-4"  v-if="!$root.$refs.BaseLayout.isEmployee">
                      <button v-if="terminationDefaultDisable" class="btn ng-binding ng-scope btn-danger"
                        @click="cancelTermination()" style="margin: 10px 0 10px 0">
                        Cancel Termination
                      </button>
                      <button v-else class="btn ng-binding ng-scope btn-danger" 
                        @click="terminateEmployeeClicked()" style="margin: 10px 0 10px 0">
                        Terminate
                      </button>
                  </div>
                </div>
                <div class="row" style="margin-top: 10px;">
                  <div class="col-sm-3">
                    <div class="form-group">
                      <v-text-field
                        v-model="job"
                        name="job"
                        class="ng-job-title"
                        placeholder="Product Manager"
                        label="Job title"
                        type="text"
                        maxlength="40"
                        :rules="[rules.required,rules.nameRules]"
                        required
                        outlined
                        color="success"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                      <v-menu
                        v-model="employmentStartDateMenu"
                        :close-on-content-click="false"
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :value="computedStartDateFormatted"
                            name="startDate.date"
                            label="Start Date"
                            class="ng-start-date"
                            v-bind="attrs"
                            v-on="on"
                            @click:clear="startDate = null"
                            :rules="[rules.required,rules.dateRules]"
                            required
                            outlined
                            color="success"
                            append-icon="mdi-calendar"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="startDate"
                          @change="employmentStartDateMenu = false"
                          no-title
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <label class="txt-title-input">Termination Date</label>
                    <div class="form-group" style="height: 10px">
                      {{ computedTerminationDateFormatted }}
                    </div>
                  </div>
                  <div class="col-sm-3" style="text-align: center">
                    <label class="txt-title-input">Termination Amount</label>
                    <div class="form-group" style="height: 10px">
                      ${{ processTerminationAmount>0 ? processTerminationAmount.toFixed(2):terminationAmount }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <v-select
                        :attach="true"
                        @change="onEmploymentTypeChange()"
                        class="ng-employment"
                        v-model="employmentType"
                        :items="listEmploymentType"
                        label="Employment Type"
                        required
                        outlined
                        color="success"
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <v-text-field
                        class="ng-weekly-work-hours"
                        v-model="weeklyWorkHours"
                        name="weeklyWorkHours"
                        v-show="employmentType != 'FullTime'"
                        placeholder="40"
                        label="Weekly work hours"
                        step="0.01"
                        type="number"
                        maxlength="40"
                        :rules="[rules.required,rules.numberRules]"
                        required
                        outlined
                        color="success"
                      ></v-text-field>
                      <v-text-field
                        class="ng-weekly-work-hours"
                        v-show="employmentType == 'FullTime'"
                        :readonly="isLock"
                        v-model.number="weeklyWorkHours"
                        name="weeklyWorkHours"
                        placeholder="40"
                        label="Weekly work hours"
                        step="0.01"
                        type="text"
                        maxlength="40"
                        :rules="[rules.required,rules.numberRules]"
                        outlined
                        color="success"
                        :prepend-inner-icon="
                          isLock ? 'mdi-lock' : 'mdi-lock-open'
                        "
                        @click:prepend-inner="isLock = !isLock"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="col-sm-4 ng-scope">
                    <div v-show="employmentType != 'Casual'" class="form-group">
                      <v-text-field
                        v-model.number="leaveLoading"
                        name="leaveLoading"
                        placeholder="0"
                        label="Leave Loading (%)"
                        step="0.01"
                        type="number"
                        maxlength="40"
                        :rules="[rules.required]"
                        required
                        outlined
                        color="success"
                      ></v-text-field>
                    </div>
                    <div v-show="employmentType == 'Casual'" class="form-group">
                      <v-text-field
                        v-model.number="casualLoading"
                        name="casualLoading"
                        placeholder="25"
                        label="Casual Loading (%)"
                        step="0.01"
                        type="number"
                        maxlength="40"
                        :rules="[rules.required]"
                        required
                        outlined
                        color="success"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-4">
                    <div class="form-group">
                      <v-select
                        :attach="true"
                        v-model="payPeriod"
                        @change="changeInfoEmployee('payPeriod', payPeriod)"
                        :items="listPayPeriod"
                        label="Pay Period"
                        required
                        outlined
                        color="success"
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <v-text-field
                        class="ng-pay-rate"
                        v-model="payRate"
                        name="payRate"
                        placeholder="40"
                        label="Pay Rate"
                        type="text"
                        maxlength="40"
                        :rules="[rules.required,rules.numberRules]"
                        required
                        outlined
                        color="success"
                        prepend-inner-icon="mdi-currency-usd"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group mb-3">
                      <v-select
                        v-if="!$root.$refs.BaseLayout.isEmployee"
                        :attach="true"
                        v-model="typeRate"
                        @change="changeInfoEmployee('typeRate', typeRate)"
                        :items="listTypeRate"
                        required
                        outlined
                        color="success"
                      ></v-select>
                    </div>
                  </div>
                </div>
                <div class="row" v-show="reFormatMoney(payRate) > 0">
                  <div class="col-4"></div>
                  <div class="col-8">
                    <div
                      v-if="typeRate != 'PerHour'"
                      class="text-success ng-binding"
                      style="position: absolute; top: -30px"
                    >
                      Equivalent to ${{ payRateUI() }} per hour
                      <i
                        class="icon-feather-help-circle text-primary ng-hide"
                        ng-click="hourlyRateInfo()"
                        ng-show="employeeDetails_new.ty === 'c'"
                      ></i>
                    </div>
                    <div
                      v-else
                      class="text-success ng-binding"
                      style="position: absolute; top: -30px"
                    >
                      Equivalent to ${{ payRateUI() }} per year
                      <i
                        class="icon-feather-help-circle text-primary ng-hide"
                        ng-click="hourlyRateInfo()"
                        ng-show="employeeDetails_new.ty === 'c'"
                      ></i>
                    </div>
                  </div>
                </div>
                <!-- Form Bank -->
                <h5 style="margin-top: 10px">Payment Method</h5>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <v-select
                        :attach="true"
                        class="ng-bank"
                        v-model="paymentMethod"
                        @change="
                          changeInfoEmployee(
                            'bank.paymentMethod',
                            paymentMethod
                          )
                        "
                        :items="listPaymentMethod"
                        required
                        outlined
                        color="success"
                      ></v-select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="paymentMethod === 'Bank'" style="">
              <h5>Bank details</h5>
              <div class="row fade ng-scope">
                <div class="col-sm-5">
                  <div class="form-group">
                    <v-text-field
                      class="ng-account-name"
                      v-model="bankAccountName"
                      name="bank.bankAccountName"
                      placeholder=""
                      label="Account Name"
                      type="text"
                      maxlength="40"
                      :rules="[rules.required,,rules.nameRules]"
                      required
                      outlined
                      color="success"
                    ></v-text-field>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <v-text-field
                      class="ng-bsb"
                      v-model="bankAccountBSB"
                      name="bank.bankAccountBSB"
                      placeholder="000000"
                      label="BSB"
                      type="text"
                      maxlength="6"
                      :rules="[rules.required, rules.numberRules]"
                      required
                      outlined
                      color="success"
                    ></v-text-field>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <v-text-field
                      class="ng-account-number"
                      v-model="bankAccountNumber"
                      name="bank.bankAccountNumber"
                      placeholder=""
                      label="Acc. Number"
                      type="text"
                      minlength="6"
                      maxlength="9"
                      :rules="[rules.required,rules.numberRules]"
                      required
                      outlined
                      color="success"
                      messages="6~9 Digits"
                    ></v-text-field>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>

          <el-tab-pane label="Tax" name="Tax">
            <!-- Tax -->
            <div class="DivToBeShownToEmployee" v-if="$root.$refs.BaseLayout.isEmployee">
              <div class="row">
                
                <div class="col-sm-4">
                  <label class="txt-title-input">Status Residency</label>
                  <div class="form-group" style="height: 10px">
                    {{ statusResidency }}
                  </div>
                </div>
                <div class="col-sm-4">
                  <div v-if="!isExemption">
                    <label class="txt-title-input">Tax File Number</label>
                    <div class="form-group" style="height: 10px">
                      {{ taxNumber }}
                    </div>
                  </div>
                  <div v-else>
                    <label class="txt-title-input">Tax File Number</label>
                    <div class="form-group" style="height: 10px">
                      {{ tfnExemption }}
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-12">
                  <h6>Additional Information</h6>
                  <div class="form-check" style="margin: 5px 0">
                    <input
                      v-model="claimTaxFreeThreshold"
                      name="claimTaxFreeThreshold"
                      class="
                        form-check-input
                        ng-pristine ng-untouched ng-valid ng-empty
                      "
                      type="checkbox"
                      value=""
                      :disabled="true"
                    />
                    <label class="form-check-label" for="claimTaxFreeThreshold">
                      Claim tax free threshold
                    </label>
                  </div>
                  <div class="form-check" style="margin: 5px 0">
                    <input
                      v-model="hasHecsOrHelpDebt"
                      name="hasHecsOrHelpDebt"
                      class="
                        form-check-input
                        ng-pristine ng-untouched ng-valid ng-empty
                      "
                      type="checkbox"
                      value=""
                      :disabled="true"
                    />
                    <label class="form-check-label" for="hasHecsOrHelpDebt">
                      <!-- Has HECS or HELP debt -->
                      Has study and training support loan debt 
                        <i class="fa fa-info-circle" title="Any of the following-
                        Higher Education Loan Program (HELP) debt
                        VET Student Loan (VSL) debt
                        Financial Supplement (FS) debt
                        Student Start-up Loan (SSL) debt (including ABSTUDY SSL debts)
                        Trade Support Loan (TSL) debt"></i>
                    </label>
                  </div>
                  <!-- <div class="form-check" style="margin: 5px 0">
                    <input
                      :disabled="true"
                      v-model="hasTslOrStudentStart"
                      name="hasTslOrStudentStart"
                      class="
                        form-check-input
                        ng-pristine ng-untouched ng-valid ng-empty
                      "
                      type="checkbox"
                      value=""
                    />
                    <label class="form-check-label" for="hasTslOrStudentStart">
                      Has TSL or Student Start-up Loan
                    </label>
                  </div> -->
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <div v-if="hasTaxTemplate">
                    <label class="txt-title-input">Overwrite tax</label>
                    <div class="form-group" style="height: 10px">
                      {{ taxTemplate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="DivNotToBeShownToEmployee">
              <div
                v-show="this.styleTab == 'Tax'"
                class="text-left"
                ng-show="currentTab == 'tax'"
                style="">
                
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <v-select
                        :attach="true"
                        class="ng-Status-Residency"
                        v-model="statusResidency"
                        @change="statusResidencyChange()"
                        label="Status of Residency"
                        :items="listStatusResidency"
                        required
                        outlined
                        color="success"
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group" style="margin-bottom: 2px">
                      <v-text-field
                        class="ng-tax-file-number"
                        v-show="!isExemption"
                        v-model="taxNumber"
                        name="taxNumber"
                        placeholder=""
                        label="Tax File Number"
                        type="text"
                        maxlength="40"
                        outlined
                        color="success"
                      ></v-text-field>
                      <v-select
                        :attach="true"
                        v-show="isExemption"
                        v-model="tfnExemption"
                        @change="
                          changeInfoEmployee('tfnExemption', tfnExemption)
                        "
                        label="Tax File Number"
                        :items="listTfnExemption"
                        outlined
                        color="success"
                      ></v-select>
                    </div>
                    <div style="position: absolute; top: 50px">
                      <div
                        class="small-label os-dropdown-trigger text-primary"
                        v-show="!isExemption"
                        v-on:click="clickTaxFileNumber()"
                      >
                        No tax file number?
                      </div>
                      <div
                        class="small-label os-dropdown-trigger text-primary"
                        v-show="isExemption"
                        v-on:click="clickTaxFileNumber()"
                      >
                        Has TFN?
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <h6>Additional Information</h6>
                    <div class="form-check" style="margin: 5px 0">
                      <input
                        v-model="claimTaxFreeThreshold"
                        name="claimTaxFreeThreshold"
                        class="
                          form-check-input
                          ng-pristine ng-untouched ng-valid ng-empty
                        "
                        type="checkbox"
                        value=""
                        id="claimTaxFreeThreshold"
                      />
                      <label
                        class="form-check-label"
                        for="claimTaxFreeThreshold"
                      >
                        Claim tax free threshold
                      </label>
                    </div>
                    <div class="form-check" style="margin: 5px 0">
                      <input
                        v-model="hasHecsOrHelpDebt"
                        name="hasHecsOrHelpDebt"
                        class="
                          form-check-input
                          ng-pristine ng-untouched ng-valid ng-empty
                        "
                        type="checkbox"
                        value=""
                        id="hasHecsOrHelpDebt"
                      />
                      <label class="form-check-label" for="hasHecsOrHelpDebt">
                        <!-- Has HECS or HELP debt -->
                        Has study and training support loan debt 
                        <i class="fa fa-info-circle" title="Any of the following-
                        Higher Education Loan Program (HELP) debt
                        VET Student Loan (VSL) debt
                        Financial Supplement (FS) debt
                        Student Start-up Loan (SSL) debt (including ABSTUDY SSL debts)
                        Trade Support Loan (TSL) debt"></i>
                      </label>
                    </div>
                    <!-- <div class="form-check" style="margin: 5px 0">
                      <input
                        v-model="hasTslOrStudentStart"
                        name="hasTslOrStudentStart"
                        class="
                          form-check-input
                          ng-pristine ng-untouched ng-valid ng-empty
                        "
                        type="checkbox"
                        value=""
                        id="hasTslOrStudentStart"
                      />
                      <label
                        class="form-check-label"
                        for="hasTslOrStudentStart"
                      >
                        Has TSL or Student Start-up Loan
                      </label>
                    </div> -->
                  </div>
                </div>
                <div class="row" style="margin-top: 10px">
                  <div class="col-12">
                    <div
                      @click="checkOverwriteTax()"
                      class="row text-left"
                      style="margin-top: 15px"
                    >
                      <div class="text-left" style="margin-left: 20px">
                        <v-switch
                          id="overwriteTax"
                          v-model="hasTaxTemplate"
                          name="hasTaxTemplate"
                          inset
                          style="margin-top: 0px"
                        ></v-switch>
                      </div>
                      <div class="text-right" style="margin-top: 3px">
                        <label for="overwriteTax">Overwrite tax</label>
                      </div>
                    </div>
                  </div>
                  <div
                    v-show="hasTaxTemplate"
                    class="input-group col-6"
                    style="margin-top: 5px"
                  >
                    <v-text-field
                      class="ng-taxTemplate"
                      v-model="taxTemplate"
                      name="taxTemplate"
                      placeholder=""
                      step="0.01"
                      type="number"
                      maxlength="40"
                      outlined
                      color="success"
                      prepend-inner-icon="mdi-currency-usd"
                    ></v-text-field>
                  </div>
                </div>
                <div class="row" style="margin-top: 10px">
                  <div class="col-12">
                    <h6>Tax File Number Declaration</h6>
                    <div class="form-check" style="margin: 5px 0">
                      <input
                        v-model="taxFileNumberDeclaration"
                        name="taxFileNumberDeclaration"
                        class="
                          form-check-input
                          ng-pristine ng-untouched ng-valid ng-empty
                        "
                        type="checkbox"
                        value=""
                        id="taxFileNumberDeclaration"
                      />
                      <label
                        class="form-check-label"
                        for="taxFileNumberDeclaration"
                      >
                        Tick this box if you would like to lodge TFN Declaration
                        in the next STP lodgement. By ticking this, you also
                        declare that the information you have given is true and
                        correct.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>

          <el-tab-pane label="Opening balances" name="Opening balances">
            <div class="DivToBeShownToEmployee" v-if="$root.$refs.BaseLayout.isEmployee">
              <h5>Leave Balances</h5>
              <div class="row">
                <div class="col-sm-6">
                  <label class="txt-title-input">Annual leave balance</label>
                  <div class="form-group" style="height: 10px">
                    {{ annualLeaveBalance }}
                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="txt-title-input">Sick leave balance</label>
                  <div class="form-group" style="height: 10px">
                    {{ sickLeaveBalance }}
                  </div>
                </div>
              </div>
              <br />
              <h5>Opening Balances</h5>
              <div class="row">
                <div class="col-sm-6">
                  <label class="txt-title-input">As at</label>
                  <div class="form-group" style="height: 10px">
                    {{ computedAsAtBalanceDateFormatted }}
                  </div>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-sm-6">
                  <label class="txt-title-input">Gross Earnings</label>
                  <div class="form-group" style="height: 10px">
                    ${{ grossEarningsBalance }}
                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="txt-title-input">Tax Balance</label>
                  <div class="form-group" style="height: 10px">
                    ${{ taxBalance }}
                  </div>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-sm-6">
                  <label class="txt-title-input">Super Guarantee</label>
                  <div class="form-group" style="height: 10px">
                    ${{ superGuaranteeBalance }}
                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="txt-title-input">Employer Contribution</label>
                  <div class="form-group" style="height: 10px">
                    ${{ employerContributionBalance }}
                  </div>
                </div>
              </div>
              <br />
              <h5>Allowances Balances</h5>
              <div class="row">
                <div class="col-sm-4">
                  <label class="txt-title-input">Car</label>
                  <div class="form-group" style="height: 10px">
                    ${{ carAllowanceBalance }}
                  </div>
                </div>
                <div class="col-sm-4">
                  <label class="txt-title-input">Transport</label>
                  <div class="form-group" style="height: 10px">
                    ${{ transportAllowanceBalance }}
                  </div>
                </div>
                <div class="col-sm-4">
                  <label class="txt-title-input">Laundry</label>
                  <div class="form-group" style="height: 10px">
                    ${{ laundryAllowanceBalance }}
                  </div>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-sm-4">
                  <label class="txt-title-input">Meals</label>
                  <div class="form-group" style="height: 10px">
                    ${{ mealsAllowanceBalance }}
                  </div>
                </div>
                <div class="col-sm-4">
                  <label class="txt-title-input">Travel</label>
                  <div class="form-group" style="height: 10px">
                    ${{ travelAllowanceBalance }}
                  </div>
                </div>
              </div>
              <br />
              <h5>Deduction Balances</h5>
              <div class="row">
                <div class="col-sm-4">
                  <label class="txt-title-input">Union Fees</label>
                  <div class="form-group" style="height: 10px">
                    ${{ unionFeesDeductionBalance }}
                  </div>
                </div>
                <div class="col-sm-4">
                  <label class="txt-title-input">Transport</label>
                  <div class="form-group" style="height: 10px">
                    ${{ transportAllowanceBalance }}
                  </div>
                </div>
                <div class="col-sm-4">
                  <label class="txt-title-input">Workplace Giving</label>
                  <div class="form-group" style="height: 10px">
                    ${{ workplaceGivingdeductionBalance }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Opening balances -->
            <div v-else class="DivNotToBeShownToEmployee">
              <div
                v-show="this.styleTab == 'Opening balances'"
                name="bankForm"
                class="text-left ng-pristine ng-valid ng-valid-step"
                ng-show="currentTab == 'openingBalances'"
                style=""
              >
                <h5>Leave Balances</h5>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <v-text-field
                        v-model="annualLeaveBalance"
                        name="annualLeaveBalance"
                        label="Annual leave balance"
                        placeholder="0"
                        step="0.01"
                        type="number"
                        maxlength="40"
                        :rules="[rules.numberRules]"
                        outlined
                        color="success"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="col-sm-6 ng-hide">
                    <div class="form-group">
                      <v-text-field
                        v-model="annualLeaveBalance"
                        name="annualLeaveBalance"
                        label="Long service leave balance"
                        placeholder="0"
                        step="0.01"
                        type="number"
                        maxlength="40"
                        :rules="[rules.numberRules]"
                        outlined
                        color="success"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <v-text-field
                        v-model="sickLeaveBalance"
                        name="sickLeaveBalance"
                        label="Sick leave balance"
                        placeholder="0"
                        step="0.01"
                        type="number"
                        maxlength="40"
                        :rules="[rules.numberRules]"
                        outlined
                        color="success"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <h5>Opening Balances</h5>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <v-menu
                        v-model="asAtBalanceDateMenu"
                        :close-on-content-click="false"
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :value="computedAsAtBalanceDateFormatted"
                            name="asAtBalance.date"
                            label="As at"
                            v-bind="attrs"
                            v-on="on"
                            type="text"
                            @click:clear="asAtBalance = null"
                            :rules="[rules.required,rules.dateRules]"
                            outlined
                            color="success"
                            append-icon="mdi-calendar"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="asAtBalance"
                          @change="asAtBalanceDateMenu = false"
                          no-title
                        ></v-date-picker>
                      </v-menu>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <v-text-field
                        v-model="grossEarningsBalance"
                        name="grossEarningsBalance"
                        label="Gross Earnings"
                        placeholder="0"
                        step="0.01"
                        type="number"
                        maxlength="40"
                        :rules="[rules.numberRules]"
                        outlined
                        color="success"
                        messages="(Subject to tax)"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <v-text-field
                        v-model="taxBalance"
                        name="taxBalance"
                        label="Tax Balance"
                        placeholder="0"
                        step="0.01"
                        type="number"
                        maxlength="40"
                        :rules="[rules.numberRules]"
                        outlined
                        color="success"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <v-text-field
                        v-model="superGuaranteeBalance"
                        name="superGuaranteeBalance"
                        label="Super Guarantee"
                        placeholder="0"
                        step="0.01"
                        type="number"
                        :rules="[rules.numberRules]"
                        maxlength="40"
                        outlined
                        color="success"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <v-text-field
                        v-model="employerContributionBalance"
                        name="employerContributionBalance"
                        label="Employer Contribution"
                        placeholder="0"
                        step="0.01"
                        type="number"
                        :rules="[rules.numberRules]"
                        maxlength="40"
                        outlined
                        color="success"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <h4>Allowances Balance</h4>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <v-text-field
                        v-model="carAllowanceBalance"
                        name="carAllowanceBalance"
                        label="Car"
                        placeholder="0"
                        step="0.01"
                        type="number"
                        :rules="[rules.numberRules]"
                        maxlength="40"
                        outlined
                        color="success"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <v-text-field
                        v-model="transportAllowanceBalance"
                        name="transportAllowanceBalance"
                        label="Transport"
                        placeholder="0"
                        step="0.01"
                        type="number"
                        :rules="[rules.numberRules]"
                        maxlength="40"
                        outlined
                        color="success"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <v-text-field
                        v-model="laundryAllowanceBalance"
                        name="laundryAllowanceBalance"
                        label="Laundry"
                        placeholder="0"
                        step="0.01"
                        type="number"
                        :rules="[rules.numberRules]"
                        maxlength="40"
                        outlined
                        color="success"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <v-text-field
                        v-model="mealsAllowanceBalance"
                        name="mealsAllowanceBalance"
                        label="Meals"
                        placeholder="0"
                        step="0.01"
                        type="number"
                        :rules="[rules.numberRules]"
                        maxlength="40"
                        outlined
                        color="success"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <v-text-field
                        v-model="travelAllowanceBalance"
                        name="travelAllowanceBalance"
                        label="Travel & Others"
                        placeholder="0"
                        step="0.01"
                        type="number"
                        maxlength="40"
                        :rules="[rules.numberRules]"
                        outlined
                        color="success"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <h4>Deductions Balance</h4>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <v-text-field
                        v-model="unionFeesDeductionBalance"
                        name="unionFeesDeductionBalance"
                        label="Union Fees"
                        placeholder="0"
                        step="0.01"
                        type="number"
                        :rules="[rules.numberRules]"
                        maxlength="40"
                        outlined
                        color="success"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <v-text-field
                        v-model="workplaceGivingdeductionBalance"
                        name="workplaceGivingdeductionBalance"
                        label="Workplace Giving"
                        placeholder="0"
                        step="0.01"
                        type="number"
                        :rules="[rules.numberRules]"
                        maxlength="40"
                        outlined
                        color="success"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>

          <el-tab-pane label="Pay Details" name="Pay Details">
            <div class="DivToBeShownToEmployee" v-if="$root.$refs.BaseLayout.isEmployee">
              <h5>Earnings</h5>
              <div class="row">
                <div class="col-sm-6">Ordinary work hours</div>
                <div class="col-sm-4">
                  <label class="txt-title-input">Hour</label>
                  <div class="form-group" style="height: 10px">
                    {{ getWorkHourOrdinary() }}
                  </div>
                </div>
                <div class="col-sm-2">
                  <label class="txt-title-input">Rate</label>
                  <div class="form-group" style="height: 10px">
                    ${{ payRatePerHourUI() }}
                  </div>
                </div>
              </div>
              <div v-show="this.isAddExtrahours || extraHours.length > 0">
                <div v-show="extraHours.length > 0" v-for="item in extraHours" :key="item.id">
                  <div class="row">
                    <div class="col-sm-3">
                        <label class="txt-title-input">Type</label>
                        <div class="form-group" style="height: 10px">
                            {{ item.option }}
                        </div>
                    </div>
                    <div class="col-sm-3">
                        <label class="txt-title-input">Name</label>
                        <div class="form-group" style="height: 10px">
                            {{ item.name }}
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <label class="txt-title-input">Hour</label>
                        <div class="form-group" style="height: 10px">
                            {{ item.hour }}
                        </div>
                    </div>
                    <div class="col-sm-2">
                        <label class="txt-title-input">Rate</label>
                        <div class="form-group" style="height: 10px">
                            ${{ item.rate }}
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <h5>Allowance</h5>
              <div v-show="this.isAddAllowance || allowance.length > 0">
                <div v-show="allowance.length > 0" v-for="item in allowance" :key="item.id">
                  <div class="row">
                    <div class="col-sm-3">
                      <label class="txt-title-input">Unit</label>
                      <div class="form-group" style="height: 10px">
                        {{ item.unit }}
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <label class="txt-title-input">Cost</label>
                      <div class="form-group" style="height: 10px">
                        ${{ item.cost }}
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <label class="txt-title-input">Option</label>
                      <div class="form-group" style="height: 10px">
                        {{ item.option }}
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <label class="txt-title-input">Type</label>
                      <div class="form-group" style="height: 10px">
                        {{ item.type }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <h5>Deduction</h5>
              <div v-show="this.isAddDeduction || deduction.length > 0">
                <div
                  v-show="deduction.length > 0"
                  v-for="item in deduction"
                  :key="item.id"
                >
                  <div class="row">
                    <div class="col-sm-4">
                      <label class="txt-title-input">Option</label>
                      <div class="form-group" style="height: 10px">
                        {{ item.option }}
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <label class="txt-title-input">Type</label>
                      <div class="form-group" style="height: 10px">
                        {{ item.type }}
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <label class="txt-title-input">Rate</label>
                      <div class="form-group" style="height: 10px">
                        ${{ item.amountDeduction }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <h5>Superannuation</h5>
              <div class="row">
                <div class="col-sm-3">
                  <label class="txt-title-input">Type</label>
                  <div class="form-group" style="height: 10px">
                    {{ superFundType }}
                  </div>
                </div>
                <div class="col-sm-3">
                  <label class="txt-title-input">Contribution Rate(%)</label>
                  <div class="form-group" style="height: 10px">
                    {{ contributionRate }}
                  </div>
                </div>
                <div class="col-sm-3">
                  <label class="txt-title-input">Employer Contribution</label>
                  <div class="form-group" style="height: 10px">
                    ${{ employerContribution }}
                  </div>
                </div>
                <div class="col-sm-3">
                  <label class="txt-title-input">Super Salary Sacrifice</label>
                  <div class="form-group" style="height: 10px">
                    ${{ superSalarySacrifice }}
                  </div>
                </div>
              </div>
              <br />
              <div class="row">
                <div class="col-sm-4">
                  <label class="txt-title-input">FBT</label>
                  <div class="form-group" style="height: 10px">${{ FBT }}</div>
                </div>
              </div>
              <div v-show="superFundType == 'Self Managed Superannuation Fund'">
                <div class="row">
                  <div class="col-sm-3">
                    <label class="txt-title-input">Name</label>
                    <div class="form-group" style="height: 10px">
                      {{ fundName }}
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <label class="txt-title-input">ABN</label>
                    <div class="form-group" style="height: 10px">
                      {{ fundABN }}
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <label class="txt-title-input"
                      >Electronic Service Address</label
                    >
                    <div class="form-group" style="height: 10px">
                      {{ fundAddress }}
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <label class="txt-title-input"
                      >Employer Number (optional)</label
                    >
                    <div class="form-group" style="height: 10px">
                      {{ memberNumber }}
                    </div>
                  </div>
                </div>
                <br />
                <h5>Bank Details</h5>
                <div class="row">
                  <div class="col-sm-4">
                    <label class="txt-title-input">Account Name</label>
                    <div class="form-group" style="height: 10px">
                      {{ fundBankName }}
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <label class="txt-title-input">BSB</label>
                    <div class="form-group" style="height: 10px">
                      {{ fundBankBSB }}
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <label class="txt-title-input">Account Number</label>
                    <div class="form-group" style="height: 10px">
                      {{ fundBankNumber }}
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div v-show="isShowDetailSupperFund" class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label for="">Super Fund Name</label>
                    <div style="margin-top: 10px" class="">
                      <b class="ng-binding"></b>
                      <b class="ng-binding" style="margin-top: 20px">{{
                        organisationName
                      }}</b>
                    </div>
                    <div style="margin-top: 10px" class="">
                      <b class="ng-binding"></b>
                      <div class="ng-binding">ABN : {{ identifierValue }}</div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label class="txt-title-input">Number</label>
                  <div class="form-group" style="height: 10px">
                    {{ memberNumber }}
                  </div>
                </div>
              </div>
            </div>
            <!-- Pay Details -->
            <div v-else class="DivNotToBeShownToEmployee">
              <div
                v-show="this.styleTab == 'Pay Details'"
                name="Pay Details"
                class="text-left ng-pristine ng-valid ng-valid-step"
                ng-show="currentTab == 'template'"
                style=""
              >
                <h5>Earnings</h5>
                <div class="small pb-3">
                  You can change ordinary earning settings in the
                  <span
                    class="text-primary pointer"
                    v-on:click="setStyleTab('Employment')"
                    >Employment section</span
                  >.
                </div>
                <div class="row">
                  <div class="col-sm-6">Ordinary work hours</div>
                  <div class="col-sm-3 pb-1">
                    <div class="input-group">
                      <v-text-field
                        :value="getWorkHourOrdinary()"
                        label="Hour"
                        disabled
                        placeholder="0"
                        step="0.01"
                        type="number"
                        maxlength="40"
                        outlined
                        color="success"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="col-sm-3 pb-1">
                    <div class="input-group">
                      <v-text-field
                        :value="payRatePerHourUI()"
                        label="Rate"
                        disabled
                        placeholder="0"
                        step="0.01"
                        type="text"
                        :rules="[rules.numberRules]"
                        maxlength="40"
                        outlined
                        color="success"
                        prepend-inner-icon="mdi-currency-usd"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <!-- Add Extra hours  -->
                <div v-show="this.isAddExtrahours || extraHours.length > 0">
                    <div v-show="extraHours.length > 0" v-for="(item, index) in extraHours" :key="item.id">
                        <div class="row ng-scope" ng-repeat="extraHours in employeeDetails6_edit.tm.eh" style="margin-bottom: 5px">
                            <div class="col-12" style="margin-top: 25px">
                                <div v-on:click="removeExtrahours(index)" class="text-danger text-right os-dropdown-trigger" style="margin-top: -21px">
                                    <i class="icon-feather-trash-2"></i>
                                </div>
                            </div>
                             <div class="col-sm-3">
                              <v-select
                                :attach="true"
                                class="cutomOptionExtraHours"
                                v-model="item.option"
                                @change="
                                  changeInfoEmployee(
                                    'listOptionExtraHours.' + index + '.option',
                                    item.option,
                                    'ExtraHours'
                                  )
                                "
                                label="Option"
                                :items="listOptionExtraHoursEarnings"
                                outlined
                                color="success"
                              ></v-select>
                            </div>
                            <div class="col-sm-3">
                                <v-select
                                class="CustomSelectExtraHours"
                                :attach="true"
                                v-model="item.id"
                                label="Type"
                                :items="listOptionExtraHours"
                                @change="test1(index, item, item.option)"
                                item-text="payslipName"
                                item-value="id" 
                                outlined
                                color="success"
                                return-object
                            ></v-select>
                            </div>
                            <!--
                            <div class="col-sm-3">
                                <v-text-field
                                    v-model="item.name"
                                    :name="'listExtraHours.' + index + '.name'"
                                    label="Name"
                                    placeholder="Sunday"
                                    type="text"
                                    maxlength="40"
                                    outlined
                                    color="success"
                                ></v-text-field>
                            </div>
                            <div class="col-sm-3">
                            <div class="input-group" style="margin-bottom: 3px">
                                <v-text-field
                                    v-model="item.hour"
                                    :name="'listExtraHours.' + index + '.hour'"
                                    label="Hour"
                                    placeholder="0"
                                    step=".01"
                                    type="number"
                                    maxlength="40"
                                    outlined
                                    color="success"
                                ></v-text-field>
                            </div>
                            </div> -->
                            <div class="col-sm-3">
                                <div class="input-group" style="margin-bottom: 3px">
                                    <v-text-field
                                        v-model="item.normalRate"
                                        :name="'listExtraHours.' + index + '.rate'"
                                        label="Rate"
                                        placeholder="0"
                                        step=".01"
                                        type="number"
                                        maxlength="40"
                                        outlined
                                        color="success"
                                        
                                    ></v-text-field>
                                </div>
                            <!-- <div class="small-label">Rate</div> -->
                            </div>
                        </div>
                    </div>
                    <hr ng-if="employeeDetails6_edit.tm.eh.length>0" class="ng-scope" style=""/>
                </div>
                <div class="col-12 mb-3 text-primary os-dropdown-trigger" v-on:click="addExtrahours()">
                    + Add Extra hours
                </div>
                <!--Add Allowance  -->
                <h5>Allowance</h5>
                <div v-show="this.isAddAllowance || allowance.length > 0">
                  <div
                    v-show="allowance.length > 0"
                    v-for="(item, index) in allowance"
                    :key="item.id"
                  >
                    <div
                      class="row ng-scope"
                      ng-repeat="allowance in employeeDetails6_edit.tm.alw"
                      style="margin-bottom: 5px"
                    >
                      <div class="col-12" style="margin-top: 25px">
                        <!-- <hr> 
                                                                            Allowance -->
                        <div
                          class="text-danger text-right os-dropdown-trigger"
                          v-on:click="removeAllowance(index)"
                          style="margin-top: -21px"
                        >
                          <i class="icon-feather-trash-2"></i>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="input-group" style="margin-bottom: 6px">
                          <v-text-field
                            v-model="item.unit"
                            :name="'listAllowance.' + index + '.unit'"
                            placeholder="0"
                            step=".0001"
                            type="number"
                            maxlength="40"
                            :rules="[rules.numberRules]"
                            outlined
                            color="success"
                            label="Unit"
                          ></v-text-field>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="input-group">
                          <v-text-field
                            v-model="item.cost"
                            :name="'listAllowance.' + index + '.cost'"
                            label="Rate"
                            placeholder="0"
                            step=".0001"
                            type="number"
                            :rules="[rules.numberRules]"
                            maxlength="40"
                            outlined
                            color="success"
                            prepend-inner-icon="mdi-currency-usd"
                          ></v-text-field>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <v-select
                          :attach="true"
                          v-model="item.option"
                           class="customOptionAllowance"
                          @change="
                            changeInfoEmployee(
                              'listAllowance.' + index + '.option',
                              item.option,
                              'Allowance'
                            )
                          "
                          label="Option"
                          :items="listOptionAllowance"
                          outlined
                          color="success"
                        ></v-select>
                      </div>
                      <div class="col-sm-6">
                        <v-select
                          :attach="true"
                          v-model="item.type"
                           class="customTypeAllowance"
                          @change="
                            changeInfoEmployee(
                              'listAllowance.' + index + '.type',
                              item.type
                            )
                          "
                          label="Type"
                          :items="listTypeAllowance"
                         
                          outlined
                          color="success"
                        ></v-select>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-12 mb-3 text-primary os-dropdown-trigger"
                  v-on:click="addAllowance()"
                >
                  + Add Allowance
                </div>
                <!-- Add Deduction -->
                <h5>Deduction</h5>
                <div v-show="this.isAddDeduction || deduction.length">
                  <div
                    v-show="deduction.length > 0"
                    v-for="(item, index) in deduction"
                    :key="item.id"
                  >
                    <div
                      class="row ng-scope"
                      ng-repeat="deduction in employeeDetails6_edit.tm.ded"
                      style="margin-bottom: 5px"
                    >
                      <div class="col-12" style="margin-top: 25px">
                        <!-- Deduction -->
                        <div
                          class="text-danger text-right os-dropdown-trigger"
                          v-on:click="removeDeduction(index)"
                          style="margin-top: -21px"
                        >
                          <i class="icon-feather-trash-2"></i>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <v-select
                          :attach="true"
                          v-model="item.option"
                          @change="
                            changeInfoEmployee(
                              'listDeduction.' + index + '.option',
                              item.option,
                              'Deduction'
                            )
                          "
                          label="Option"
                          :items="listOptionDeduction"
                          outlined
                          color="success"
                        ></v-select>
                      </div>
                      <div class="col-sm-4">
                        <v-select
                          :attach="true"
                          v-model="item.type"
                          class="customTypeDeduction"
                          @change="
                            changeInfoEmployee(
                              'listDeduction.' + index + '.type',
                              item.type, item 
                            )
                          "
                          label="Type"
                          :items="listTypeDeduction"
                          outlined
                          color="success"
                        ></v-select>
                      </div>
                      <div class="col-sm-4">
                        <div class="input-group">
                          <v-text-field
                            v-model="item.amountDeduction"
                            :name="
                              'listDeduction.' + index + '.amountDeduction'
                            "
                            label="Rate"
                            placeholder="0"
                            step=".0001"
                            type="number"
                            :rules="[rules.numberRules]"
                            maxlength="40"
                            outlined
                            color="success"
                            prepend-inner-icon="mdi-currency-usd"
                          ></v-text-field>
                        </div>
                      </div>
                      <div class="col-12">
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="col-12 mb-3 text-primary os-dropdown-trigger"
                  v-on:click="addDeduction()"
                >
                  + Add Deduction
                </div>
                <h5>Superannuation</h5>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <v-select
                        :attach="true"
                        v-model="superFundType"
                        @change="
                          changeInfoEmployee(
                            'fund.superFundType',
                            superFundType
                          )
                        "
                        label="Type"
                        :items="listTypeSuper"
                        outlined
                        color="success"
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <v-text-field
                        class="ng-Contribution-Rate"
                        v-model="contributionRate"
                        name="fund.contributionRate"
                        label="Contribution Rate (%)"
                        placeholder="9.5"
                        step=".01"
                        type="number"
                        maxlength="40"
                        :rules="[rules.required,rules.numberDecimalRules]"
                        outlined
                        color="success"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <v-text-field
                        v-model="employerContribution"
                        name="employerContribution"
                        label="Employer Contribution"
                        placeholder="0"
                        step=".01"
                        type="number"
                        maxlength="40"
                        :rules="[rules.numberRules]"
                        outlined
                        color="success"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <v-text-field
                        v-model="superSalarySacrifice"
                        name="superSalarySacrifice"
                        label="Super Salary Sacrifice"
                        placeholder="0"
                        step=".01"
                        type="number"
                        :rules="[rules.numberRules]"
                        maxlength="40"
                        outlined
                        color="success"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <v-text-field
                        v-model="FBT"
                        name="fbt"
                        label="FBT"
                        placeholder="0"
                        :rules="[rules.numberDecimalRules]"
                        type="text"
                        maxlength="40"
                        outlined
                        color="success"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <div
                  v-show="superFundType == 'Self Managed Superannuation Fund'"
                  class="ng-scope"
                >
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <v-text-field
                          class="ng-Sf-Name"
                          v-model="fundName"
                          name="fund.fundName"
                          label="Name"
                          placeholder=""
                          type="text"
                          maxlength="40"
                          :rules="[rules.required]"
                          outlined
                          color="success"
                        ></v-text-field>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <v-text-field
                          class="ng-Sf-ABN"
                          v-model="fundABN"
                          name="fund.fundABN"
                          label="ABN"
                          placeholder=""
                          type="text"
                          maxlength="11"
                          :rules="[rules.required]"
                          outlined
                          color="success"
                        ></v-text-field>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <v-text-field
                          v-model="fundAddress"
                          name="fund.fundAddress"
                          label="Electronic Service Address"
                          placeholder=""
                          type="text"
                          maxlength="40"
                          outlined
                          color="success"
                        ></v-text-field>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <v-text-field
                          v-model="memberNumber"
                          name="fund.memberNumber"
                          label="Employer Number (optional)"
                          placeholder=""
                          type="text"
                          maxlength="40"
                          outlined
                          color="success"
                        ></v-text-field>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <h6>Bank details</h6>
                    </div>
                    <div class="col-sm-5">
                      <div class="form-group">
                        <v-text-field
                          class="ng-Sf-Account-Name"
                          v-model="fundBankName"
                          name="fund.fundBankName"
                          label="Account Name"
                          placeholder=""
                          type="text"
                          maxlength="40"
                          :rules="[rules.required,rules.nameRules]"
                          outlined
                          color="success"
                        ></v-text-field>
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <v-text-field
                          class="ng-Sf-BSB"
                          v-model="fundBankBSB"
                          name="fund.fundBankBSB"
                          label="BSB"
                          placeholder=""
                          type="text"
                          maxlength="6"
                          :rules="[rules.required]"
                          outlined
                          color="success"
                        ></v-text-field>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="form-group">
                        <v-text-field
                          class="ng-Sf-Account-Number"
                          v-model="fundBankNumber"
                          name="fund.fundBankNumber"
                          label="Account Number"
                          placeholder=""
                          type="text"
                          maxlength="9"
                          :rules="[rules.required,rules.numberRules]"
                          outlined
                          color="success"
                          messages="6~9 digits"
                        ></v-text-field>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-show="superFundType == 'Regulated Superannuation Fund'"
                  class="ng-scope"
                >
                  <form
                    v-on:submit.prevent="searchSupperFund()"
                    action=""
                    method="post"
                    id="search_form"
                  >
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group">
                          <v-text-field
                            v-model="edtSearchSupperFund"
                            label="Search Super Company by Name, or ABN"
                            placeholder=""
                            type="text"
                            maxlength="40"
                            outlined
                            color="success"
                          ></v-text-field>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group">
                          <button
                            type="submit"
                            class="btn btn-primary ng-search"
                            style="margin-top: 3px"
                            ng-disabled="superSearching"
                          >
                            <span
                              class="spinner-grow spinner-grow-sm ng-hide"
                            ></span>
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div class="alert alert-green ng-hide" role="alert">
                    <h6 style="margin-bottom: 10px">Select your super fund</h6>
                    <table class="table table-sm table-border-white">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">ABN</th>
                          <th scope="col">USI</th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                  <div
                    v-show="isShowListSupperFund && listSupperFund.length > 0"
                    class="alert alert-green"
                    role="alert"
                    style=""
                  >
                    <h6 style="margin-bottom: 10px">Select your super fund</h6>
                    <table class="table table-sm table-border-white">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">ABN</th>
                          <th scope="col">USI</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in listSupperFund"
                          :key="item.id"
                          @click="detailSupperFund(item.identifierValue)"
                          ng-repeat="superFundDetails in superList"
                          class="text-primary-hover pointer ng-scope"
                          style=""
                        >
                          <td class="ng-binding">
                            {{ item.organisationName }}
                          </td>
                          <td class="ng-binding">{{ item.identifierValue }}</td>
                          <td class="ng-binding">{{ item.fundProductUsi }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <el-pagination
                    class="pagination"
                    @current-change="handleCurrentChange"
                    v-show="isShowListSupperFund && totalPage > 1"
                    background
                    layout="prev, pager, next"
                    :page-size="pageSize"
                    :total="totalPage"
                  >
                  </el-pagination>
                  <table
                    v-show="isShowListSupperFund && listSupperFund.length == 0"
                    style="width: 100%"
                    class="fade ng-scope text-danger"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <b>Sorry! We cannot find this Super Company.</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div v-show="isShowDetailSupperFund" class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="">Super Fund Name</label>
                        <div
                          class="
                            small-label
                            text-danger text-right
                            os-dropdown-trigger
                          "
                          style="margin-top: -26px"
                          @click="remoteSupperFund()"
                        >
                          <i class="icon-feather-trash-2"></i>
                        </div>
                        <div style="margin-top: 10px" class="">
                          <b class="ng-binding"></b>
                          <b class="ng-binding" style="margin-top: 20px">{{
                            organisationName
                          }}</b>
                        </div>
                        <div style="margin-top: 10px" class="">
                          <b class="ng-binding"></b>
                          <div class="ng-binding">
                            ABN : {{ identifierValue }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <v-text-field
                          v-model="memberNumber"
                          class="ng-number"
                          label="Number"
                          type="text"
                          maxlength="40"
                          outlined
                          color="success"
                          :rules="[rules.required,rules.numberRules]"
                        ></v-text-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>

          <el-tab-pane label="Leave" name="Leave">
            <div class="element-box">
              <h6 class="element-header bold" style="margin-bottom: 1rem">
                Leave Balance
              </h6>
              <div class="row">
                <div class="col-6">
                  <div class="form-group leave annual">
                    <div style="padding: 4.5%">
                      <div>
                        <b class="ng-binding bold" style="font-size: 25px">{{
                          annualLeave
                        }}</b>
                        <span
                          class="smaller lighter ml-0 ng-binding"
                          style="padding-left: 10px"
                          >hours</span
                        >
                      </div>
                      <label class="small-label">Annual leave</label>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group leave sick">
                    <div style="padding: 4.5%">
                      <div>
                        <b class="ng-binding bold" style="font-size: 25px">{{
                          sickLeave
                        }}</b>
                        <span
                          class="smaller lighter ml-0 ng-binding"
                          style="padding-left: 10px"
                          >hours</span
                        >
                      </div>
                      <label class="small-label">Sick leave</label>
                    </div>
                  </div>
                </div>
              </div>
              <h6 class="element-header bold" style="margin-bottom: 1rem">
                Leave Entitlements
              </h6>
              <div class="table-responsive" style="padding-bottom: 20px">
                <div
                  v-show="listScheduledLeaves.length == 0"
                  style="margin-bottom: 15px"
                  class="ng-scope"
                >
                  No leave submitted yet!
                </div>
                <table
                  v-show="listScheduledLeaves.length > 0"
                  class="table table-padded"
                  style="background: #f7f7f7; padding: 5px"
                >
                  <thead>
                    <tr>
                      <th>
                        <span
                          v-on:click="sort('status', 'L', false)"
                          class="sort"
                          >Status
                          <i
                            :class="
                              sortKeyL != 'status'
                                ? 'icon-sort'
                                : reverseL
                                ? 'icon-sort-up'
                                : 'icon-sort-down'
                            "
                          ></i
                        ></span>
                      </th>
                      <th class="text-center">
                        <span
                          v-on:click="sort('startDate', 'L', true)"
                          class="sort"
                          >Date
                          <i
                            :class="
                              sortKeyL != 'startDate'
                                ? 'icon-sort'
                                : !reverseL
                                ? 'icon-sort-up'
                                : 'icon-sort-down'
                            "
                          ></i
                        ></span>
                      </th>
                      <th class="text-center">
                        <span v-on:click="sort('type', 'L', false)" class="sort"
                          >Type
                          <i
                            :class="
                              sortKeyL != 'type'
                                ? 'icon-sort'
                                : reverseL
                                ? 'icon-sort-up'
                                : 'icon-sort-down'
                            "
                          ></i
                        ></span>
                      </th>
                      <th class="text-right">
                        <span
                          v-on:click="sort('totalHours', 'L', true)"
                          class="sort"
                          >Leave
                          <i
                            :class="
                              sortKeyL != 'totalHours'
                                ? 'icon-sort'
                                : !reverseL
                                ? 'icon-sort-up'
                                : 'icon-sort-down'
                            "
                          ></i
                        ></span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in listScheduledLeaves"
                      :key="item.id"
                      class="animatedUp ng-scope"
                    >
                      <td class="nowrap">
                        <!-- <span class="status-pill smaller green" v-bind:class="(item.status == 'Reject')?'red': (item.status == 'Pending')? 'yellow' : ''"></span> -->
                        <span
                          v-bind:class="
                            item.status == 'Reject'
                              ? 'badge status ng-binding bg-grey'
                              : item.status == 'Pending'
                              ? 'badge status ng-binding bg-yellow'
                              : 'badge status ng-binding bg-green'
                          "
                          >{{ item.status }}</span
                        >
                      </td>
                      <td class="text-center">
                        <span class="ng-binding"
                          >{{ formatDate(item.startDate) }} -
                          {{ formatDate(item.endDate) }}</span
                        >
                      </td>
                      <td class="text-center">
                        <div
                          v-bind:class="
                            item.type == 'Sick'
                              ? 'badge ng-binding badge-sick'
                              : 'badge ng-binding badge-annual'
                          "
                        >
                          {{ item.type }}
                        </div>
                      </td>
                      <td class="text-right bolder nowrap">
                        <span class="ng-binding">{{ item.totalHours }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </el-tab-pane>

          <el-tab-pane label="Payslip" name="Payslip">
            <div class="element-box">
              <h6 class="element-header bold" style="margin-bottom: 1rem">
                Payslip History
              </h6>
              <div class="table-responsive" style="padding-bottom: 5px">
                <div
                  v-show="listPayslips.length == 0"
                  style="margin-bottom: 15px"
                  class="ng-scope"
                >
                  No payslip yet!
                </div>
                <table
                  v-show="listPayslips.length > 0"
                  class="table table-padded ng-scope"
                  style="background: #f7f7f7; padding: 5px"
                  ng-if="payslips &amp;&amp; payslips.length > 0"
                >
                  <thead>
                    <tr>
                      <th class="text-left">
                        <span
                          v-on:click="sort('startPayPeriod', 'P', true)"
                          class="sort"
                          >Date
                          <i
                            :class="
                              sortKeyP != 'startPayPeriod'
                                ? 'icon-sort'
                                : !reverseP
                                ? 'icon-sort-up'
                                : 'icon-sort-down'
                            "
                          ></i
                        ></span>
                      </th>
                      <th class="text-right">
                        <span
                          v-on:click="sort('grossEarnings', 'P', true)"
                          class="sort"
                          >Earnings
                          <i
                            :class="
                              sortKeyP != 'grossEarnings'
                                ? 'icon-sort'
                                : !reverseP
                                ? 'icon-sort-up'
                                : 'icon-sort-down'
                            "
                          ></i
                        ></span>
                      </th>
                      <th class="text-right">
                        <span
                          v-on:click="sort('deductions', 'P', true)"
                          class="sort"
                          >Deductions
                          <i
                            :class="
                              sortKeyP != 'grossEarnings'
                                ? 'icon-sort'
                                : !reverseP
                                ? 'icon-sort-up'
                                : 'icon-sort-down'
                            "
                          ></i
                        ></span>
                      </th>
                      <th class="text-right">
                        <span v-on:click="sort('tax', 'P', true)" class="sort"
                          >Tax
                          <i
                            :class="
                              sortKeyP != 'tax'
                                ? 'icon-sort'
                                : !reverseP
                                ? 'icon-sort-up'
                                : 'icon-sort-down'
                            "
                          ></i
                        ></span>
                      </th>
                      <th class="text-right">
                        <span
                          v-on:click="sort('netPay', 'P', true)"
                          class="sort"
                          >Net pay
                          <i
                            :class="
                              sortKeyP != 'netPay'
                                ? 'icon-sort'
                                : !reverseP
                                ? 'icon-sort-up'
                                : 'icon-sort-down'
                            "
                          ></i
                        ></span>
                      </th>
                      <th class="text-right">
                        <span v-on:click="sort('super', 'P', true)" class="sort"
                          >Super
                          <i
                            :class="
                              sortKeyP != 'super'
                                ? 'icon-sort'
                                : !reverseP
                                ? 'icon-sort-up'
                                : 'icon-sort-down'
                            "
                          ></i
                        ></span>
                      </th>
                    </tr>
                  </thead>
                  <tbody v-for="item in listPayslips" :key="item.id">
                    <tr
                      ng-repeat="payslip in payslips"
                      class="animatedUp ng-scope"
                      style="cursor: pointer"
                      @click="getPayslipEmployee(item.id)"
                    >
                      <td class="text-left ng-binding">
                        {{ formatDate(item.startPayPeriod) }} -
                        {{ formatDate(item.endPayPeriod) }}
                      </td>
                      <td class="text-right ng-binding">
                        ${{ item.earnings }}
                      </td>
                      <td class="text-right ng-binding">
                        ${{ getDeduction(item.listDeduction) }}
                      </td>
                      <td class="text-right ng-binding">${{ item.tax }}</td>
                      <td class="text-right ng-binding">${{ item.netPay }}</td>
                      <td class="text-right ng-binding">${{ item.super }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import * as Validate from "@/utils/Validate.js";
import * as Utils from "@/utils/Utils.js";
import * as Logs from "@/utils/Logs.js";
import * as Session from "@/utils/SessionStore";
import * as Countries from "@/utils/Countries";
import * as DataSelect from "@/utils/DataSelect.js";
import * as eb from "@/utils/EventBus";

import ApiManager from "@/api/ApiManager";
import Token from '@/api/Token'

import VueGoogleAutocomplete from "vue-google-autocomplete";
import CountryFlag from "vue-country-flag";
import Avatar from "vue-avatar";
import moment from "moment";
import axios from "axios"

import {
  SEARCH_SUPERFUND,
  DETAIL_SUPERFUND,
  UPDATE_EMPLOYEE,
  CHECK_EMAIL,
  CHECK_CONTACT_NUMBER,
  CHECK_TAX_NUMBER,
  DETAIL_LEAVE,
  GET_LIST_EMPLOYEE_LEAVE_EMPLOYEE_ONLY,
} from "@/store/actionsType";
export default {
  name: "NewEditEmployee",
  props: {},
  components: {
    // DatePicker,
    VueGoogleAutocomplete,
    CountryFlag,
    Avatar
  },
  data() {
    return {
      terminationCheck:false, // Termination Check
      terminationDefaultDisable:true,
      listPolicy: {
        updateEmployee: true,
        deleteEmployee: true,
      },
      arrayChange: {
        add: {},
        remove: [],
        changes: [],
      },
      ogirinData: null,
      utils: Utils,
      countries: [],
      isLock: true,
      sortKeyL: "",
      sortKeyP: "",
      reverseL: false,
      reverseP: false,
      listEmployees: [],
      currentEmployee: "",
      isShowAvatar: true,
      isValidate: true,
      isClickTax: false,
      employee: "",
      styleTab: "Profile",

      // Detail employee
      detailEmployeeId: "",
      detailLeave: "",
      annualLeave: 0,
      sickLeave: 0,
      listScheduledLeaves: [],
      listPayslips: [],

      //******Profile******
      firstName: "",
      lastName: "",
      gender: "",
      avatar: "",
      email: "",
      contactNumber: "",
      address: "",
      isCheckFindMyAddress: false,
      address1: "",
      address2: "",
      suburb: "",
      postcode: "",
      state: "",
      countryCode: "",
      country: "",
      active: "",
      activeEmployee: "",
      employeeUserId: "",
      //******Bank and Super******
      //===> Bank
      paymentMethod: "Cash",
      bankAccountName: "",
      bankAccountBSB: "",
      bankAccountNumber: "",

      //===> Supper Fund
      superFundType: "Regulated Superannuation Fund",
      contributionRate: 9.5,
      fundName: "",
      fundABN: "",
      fundAddress: "",
      fundUSI: "",
      fundEmployerNumber: "",
      memberNumber: "",
      fundBankName: "",
      fundBankBSB: "",
      fundBankNumber: "",
      totalPage: 0,
      pageSize: 10,
      currenPage: 1,

      //===> isCheck
      listSupperFund: [],
      listSupperFundFull: [],
      isShowListSupperFund: false,
      edtSearchSupperFund: "",
      isShowDetailSupperFund: false,
      organisationName: "",
      identifierValue: "",

      //******Employment******
      job: "",
      startDate: "",
      terminationDate: "",
      terminationAmount: 0,
      processTerminationAmount:0,
      employmentType: "",
      weeklyWorkHours: "",
      leaveLoading: 0,
      casualLoading: 0,
      payPeriod: "",
      payRatePerHour: "",
      payRate: "",
      typeRate: "PerHour",

      //******Tax******

      /** yyyy-mm-dd */
      birthDate: "",

      statusResidency: "",
      isExemption: false,
      tfnExemption: "NoTFNQuoted",
      taxNumber: "",
      claimTaxFreeThreshold: false,
      hasHecsOrHelpDebt: false,
      // hasTslOrStudentStart: false,
      hasFinancialSupplementDebt: false,
      taxFileNumberDeclaration: false,
      hasTaxTemplate: false,
      taxTemplate: 0,

      //******Opening balances******
      annualLeaveBalance: 0,
      sickLeaveBalance: 0,
      asAtBalance: "",
      grossEarningsBalance: 0,
      taxBalance: 0,
      superGuaranteeBalance: 0,
      employerContributionBalance: 0,
      carAllowanceBalance: 0,
      transportAllowanceBalance: 0,
      laundryAllowanceBalance: 0,
      mealsAllowanceBalance: 0,
      travelAllowanceBalance: 0,

      unionFeesDeductionBalance: 0,
      workplaceGivingdeductionBalance: 0,
      //******Template******
      // data for earnings
      isAddExtrahours: false,
      extraHours: [],
      // data for allowance
      isAddAllowance: false,
      allowance: [],
      // data for deduction
      isAddDeduction: false,
      deduction: [],
      bonus: [],
      isShowETP: false,
      termination: [],
      superGuarantee: 0,
      superSalarySacrifice: 0,
      employerContribution: 0,
      FBT: 0,
      employeeSubmit: "",
      //******note******
      notes: null,
      weeklyWorkHoursDefault: 0,
      
      //Valadate
      rules: {
        required: (value) => !!value || "",
        animal: [(val) => (val || "").length > 0 || "This field is required."],
        isPhone: (val) =>
          (!isNaN(val.replace(/[\s()+]+/g, "")) &&
            ((val.indexOf("0") == 0 && val.replace(/\s+/g, "").length <= 11) ||
              (val.indexOf("+") == 1 && val.replace(/\s+/g, "").length <= 15) ||
              "Phone numbers have a maximum of 11 numbers")) ||
          "phone number must be number",
        dateRules:(v) => /^([0]?[1-9]|[1|2][0-9]|[3][0|1])[./-]([0]?[1-9]|[1][0-2])[./-]([0-9]{4}|[0-9]{2})$/.test(v) || "Only Date",
        nameRules: (v) => /^[a-zA-Z ]*$/.test(v) || "Only alphabets.",
        numberRules: (v) =>/^\d+$/.test(v) || "Only numbers.",// /^[0-9]*$/
        numberDecimalRules: (v) =>/^([0-9]+\.?[0-9]*|\.[0-9]+)$/.test(v) || "Only numbers with/without decimal.",// /^[0-9]*$/
        //min: v => v.length >= 0 || 'Min 8 characters',
        // emailMatch: () => (`The email and password you entered don't match`),
      },

      //Data
      listGender: DataSelect.getList("listGender"),
      listState: DataSelect.getList("listState"),
      listEmploymentType: DataSelect.getList("listEmploymentType"),
      listPayPeriod: DataSelect.getList("listPayPeriod"),
      listTypeRate: DataSelect.getList("listTypeRate"),
      listPaymentMethod: DataSelect.getList("listPaymentMethod"),
      listStatusResidency: DataSelect.getList("listStatusResidency"),
      listTfnExemption: DataSelect.getList("listTfnExemption"),
     listOptionExtraHoursEarnings: DataSelect.getList("listOptionExtraHours"),
      listOptionAllowance: DataSelect.getList("listOptionAllowance"),
      listTypeAllowance: [],
      listOptionDeduction: DataSelect.getList("listOptionDeduction"),
      listTypeDeduction: [],
      listTypeSuper: DataSelect.getList("listTypeSuper"),
      listOptionExtraHours:[],
      
      
      employmentStartDateMenu: false,
      employmentTerminationDateMenu: false,
      taxBirthDateMenu: false,
      asAtBalanceDateMenu: false,
    };
  },

  created: function () {
    this.$root.$refs.NewEditEmployee = this;
    this.listPolicy = this.$root.$refs.BaseLayout.listPolicy;
    this.createPage();
  },

  beforeDestroy() {
    eb.EventBus.$off(eb.EMPLOYEE_TERMINATED_EVENT, this.employeeTerminatedHandler);
  },

  mounted() {
    
    eb.EventBus.$on(eb.EMPLOYEE_TERMINATED_EVENT, this.employeeTerminatedHandler);

    this.newDirtyForm();
    
    const data = {
      allowancesPayItem:[],
      deductionsEmpPayItem:[],
      earningsPayItem:[],
      companyId: Session.get("currenCompanyId"),
    };

    ApiManager.addPayItems(data).then((response) => {
      this.listOptionExtraHours=response.data.earningsPayItem

      response.data.allowancesPayItem.map((allowance) => {
      
        if (allowance.payslipName === 'OTHER') {
            this.listTypeAllowance.push({ value: (allowance.otherName).replace(" ", '_').toUpperCase(), text: (allowance.otherName).toLowerCase()})
        } else {
            this.listTypeAllowance.push({ value: (allowance.payslipName).replace(" ", '_').toUpperCase(), text: (allowance.payslipName).toLowerCase()})
        }
      })

      response.data.deductionsEmpPayItem.map((deduction) => {
         if (deduction.payslipName === 'OTHER') {
             this.listTypeDeduction.push({value: (deduction.otherName).replace(" ", '_').toUpperCase(), text: (deduction.otherName).toLowerCase()})
          } else {
            this.listTypeDeduction.push({value: (deduction.payslipName).replace(" ", '_').toUpperCase(), text: (deduction.payslipName).toLowerCase()})
          }
       
      })
    })

  },

  methods: {
    initTerminationMethod(employeeId) {
      const data = {
        companyId: Session.get("currenCompanyId"),
        employeeId: employeeId
      }
      ApiManager.terminationApiCall(data).then((response) => {
        Logs.logDebug("terminationCheck: ", response)
        if(parseInt(response) > 0 ) {
          this.terminationAmount = 0
          this.processTerminationAmount = response;
          this.terminationCheck = true;
          this.terminationDefaultDisable= true;
        } else {
            this.processTerminationAmount = 0;
            var LeaveBalance = this.projectedLeaveBalance();
            var hourlyRate = this.payRatePerHour;
            this.terminationAmount = (LeaveBalance * hourlyRate).toFixed(2);
            this.terminationCheck = false;
        }
      })
    },

    employeeTerminatedHandler(terminateEmployeeRequest) {
      Logs.logDebug("employeeTerminatedHandler", terminateEmployeeRequest);
      this.initTerminationMethod(terminateEmployeeRequest.employeeId);
      this.terminationDefaultDisable = true;
      this.terminationDate = terminateEmployeeRequest.terminationDate;
      Utils.toastSuccess("Employee terminated");
    },
    
    async terminateEmployeeClicked() {
      Logs.logDebug("terminateEmployeeClicked");

      const params = {
        employeeId: this.currentEmployee.id
      };

      this.$modal.show("modal-terminate-employee", params);
    },

    async cancelTermination() {
      if (this.terminationCheck) {
        const result = await this.$swal({
          title: "Cancel Termination",
          text: "You can not cancel this termination because your payroll is already processed with this termination amount",
          type: "warning",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
        return result?.isConfirmed ?? false;
      }
      
      const confirmedCancel = await this.$swal({
        title: "Cancel Termination",
        text: "Are you sure you want to cancel the termination",
        type: "warning",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Yes",
        showCancelButton: true,
      });

      if (!confirmedCancel.isConfirmed) return;

      try {
        const postData = {
          employeeId: this.currentEmployee.id,
        };

        await ApiManager.post(`/api/Employee/CancelEmployeeTermination`, postData);

        // success - set to non terminated state
        this.terminationDate = null;
        this.terminationAmount = null;
        this.terminationDefaultDisable = false;

        Utils.toastSuccess("Termination cancelled successfully");
      }
      catch(ex) {
        Logs.logError('cancelTermination', ex);
        Utils.toastError("An error has occurred, please try again later.");
      }
    },
    saveTerminationDate() {
      let data= {
        companyId: Session.get("currenCompanyId"),
        employeeId: this.currentEmployee.id,
        terminationDate:null,
        terminationAmount: 0
      }
      ApiManager.saveTerminationDate(data)
    },
    checkActiveEmployee() {
      if (
        this.birthDate != "" &&
        this.statusResidency != null &&
        (this.taxNumber != "" ||
          (this.tfnExemption != null &&
            this.contactNumber != "" &&
            this.address1 != "" &&
            this.suburb != "" &&
            this.postcode != "" &&
            this.state != null))
      ) {
        this.activeEmployee = true;
      } else {
        this.$modal.show("modal-taxemployee", {
          data: this.currentEmployee,
        });
      }
    },
    focusInput(id) {
      var input = document.getElementById(id);
      var parent = input.parentElement;
      var label = parent.querySelector("label");
      label.classList.add("focus");
      label.classList.add("valid");
    },
    blurInput(id) {
      var input = document.getElementById(id);
      var parent = input.parentElement;
      var label = parent.querySelector("label");
      label.classList.remove("focus");
    },
    keyupInput(id) {
      var input = document.getElementById(id);
      var parent = input.parentElement;
      var label = parent.querySelector("label");
      if (input.classList.contains("isNotNull")) {
        if (input.value == undefined || input.value == "") {
          input.classList.add("is-invalid");
          label.classList.add("text-danger");
        } else {
          input.classList.remove("is-invalid");
          label.classList.remove("text-danger");
        }
      }
    },
    gotoPayment() {
      this.$root.$refs.BaseLayout.gotoPayment();
    },
    createPage() {
      this.countries = [];
      var keys = Object.keys(Countries.getList());
      for (var i = 0; i < keys.length; i++) {
        this.countries.push({
          text: Countries.getCountry(keys[i]),
          value: keys[i].toLowerCase(),
        });
      }
      this.getListCurrentEmployee();
      this.weeklyWorkHoursDefault = Session.get("workHours") * 5;
    },
    querySearch(queryString, cb) {
      var listDrop = this.countries;
      queryString = this.country;
      var results = queryString
        ? listDrop.filter(this.createFilter(queryString))
        : listDrop;
      cb(results);
    },
    clickAutocomplete() {
      this.country = "";
    },
    getBackAutocompete() {
      var timeout = setTimeout(() => {
        if (this.country == "") {
          this.country = Countries.getCountry(this.countryCode);
        }
        var country = Countries.getCountry(this.countryCode);
        this.country = country;
        clearTimeout(timeout);
      }, 300);
    },
    createFilter(queryString) {
      return (country) => {
        return (
          country.text.toLowerCase().indexOf(queryString.toLowerCase()) != -1
        );
      };
    },
    changeLanguage(item) {
      this.country = item.text;
      this.countryCode = item.value;
      if (this.countryCode != "au") {
        this.state = "OTHER";
        window.$(".ng-state")[0].disabled = true;
      } else {
        window.$(".ng-state")[0].disabled = false;
      }
    },
    getListCurrentEmployee() {
      if (this.$root.$refs.People != undefined) {
        this.listEmployees = this.$root.$refs.People.listEmployees;
        if (Session.get("PeopleId") != undefined) {
          var isCheck = false;
          for (var i = 0; i < this.listEmployees.length; i++) {
            if (Session.get("PeopleId") == this.listEmployees[i].id) {
              isCheck = true;
              this.currentEmployee = this.listEmployees[i];
              
              break;
            }
          }
          if (!isCheck) {
            this.currentEmployee = this.listEmployees[0];
          }
        } else {
          this.currentEmployee = this.listEmployees[0];
        }
        if (this.currentEmployee != undefined && this.currentEmployee != "") {
          Session.set("PeopleId", this.currentEmployee.id);
          this.setDataDetail();
        }
        if (this.$root.$refs.BaseLayout.isEmployee) {
          if (this.currentEmployee != undefined) {
            this.employeeUserId = this.currentEmployee.employeeUserId;
          }
        }
        if(this.currentEmployee && this.currentEmployee.id) {
          this.initTerminationMethod(this.currentEmployee.id);
        }
       
         
      }
    },

    newDirtyForm() {
      var inputs = window.$("input");
      for (var i = 0; i < inputs.length; i++) {
        if (inputs[i].name != "") {
          inputs[i].addEventListener("change", (event) => {
            this.changeInfoEmployee(
              event.target.name,
              event.target.value ? event.target.value : event.target.checked
            );
          });
        }
      }
    },
    getOgirinValue(name) {
      var array = name.split(".");
      var json = this.ogirinData;
      array.forEach((element) => {
        if (element == "date") {
          json = this.getDate(json);
        } else {
          json = json[element];
        }
      });
      return json;
    },

    test1(index, value,option  ) {
      var CustomSelectExtraHours = window.$(".CustomSelectExtraHours");
      CustomSelectExtraHours.removeClass("is-invalid");

      this.extraHours[index] = JSON.parse(JSON.stringify(value.id));
      this.extraHours[index].option = option;
        // this.extraHours = [{...this.extraHours[0],option: option}]
        // this.extraHours.forEach((hoursExtra) => {
        //   hoursExtra.option= option
        // })
       
    },

    changeInfoEmployee(name, value, item = null , optionType = null) {
      if (item) {
          if (item.type === 'OTHER') {
            item.otherName = "OTHER"
          } else {
            item.otherName = ""
          }
      }
     
      if(optionType != null ){
        if(optionType === "Allowance"){
            var customTypeAllowance = window.$(".customTypeAllowance");
            customTypeAllowance.removeClass("is-invalid");
        }
        else if(optionType === "ExtraHours"){
          var CustomSelectExtraHours = window.$(".CustomSelectExtraHours");
          CustomSelectExtraHours.removeClass("is-invalid");
        }
        else if(optionType === "Deduction"){
          var customTypeDeduction = window.$(".customTypeDeduction");
          customTypeDeduction.removeClass("is-invalid");
        }
      }

      var ogirinValue = this.getOgirinValue(name);
      var index = this.arrayChange.changes.indexOf(name);

      if (value != ogirinValue) {
        if (index == -1) {
          this.arrayChange.changes.push(name);
        }
      } else {
        if (index != -1) {
          this.arrayChange.changes.splice(index, 1);
        }
      }
    },

    checkChangeInfoEmployee() {
      //Logs.json('arrayChange', this.arrayChange)
      if (this.arrayChange.changes.length > 0) {
        return this.$swal({
          title: "Confirm discard changes",
          text: "Your changes have not been saved. Do you want to SAVE?",
          type: "warning",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Yes",
          denyButtonText: "No",
          showDenyButton: true,
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed || result.isDenied) {
            this.arrayChange = {
              add: {},
              remove: [],
              changes: [],
            };
          }
          return result
        });
      } else {
        return true;
      }
    },

    updateCurrentEmployee(employee) {
      this.currentEmployee = employee;
      Session.set("PeopleId", employee.id);
      this.isLock = true;
      this.setDataDetail();
      this.checkStatusResidency(this.statusResidency);
    },

    async onClickItemPeople(employee) {
      if (this.currentEmployee.id != employee.id) {
        var isChange = await this.checkChangeInfoEmployee();
        if (isChange === true || isChange?.isDenied) {
          this.updateCurrentEmployee(employee)
        }
        if (isChange?.isConfirmed) {
          await this.updateEmployee()
          this.updateCurrentEmployee(employee)
        }
      }
    },

    setDataDetail() {
      Session.set("ogirinCurrentEmployee", this.currentEmployee);
      this.ogirinData = Session.get("ogirinCurrentEmployee");
      Session.remove("ogirinCurrentEmployee");
      this.currentEmployee = this.ogirinData;
      if (this.styleTab == "Leave" || this.styleTab == "Payslip") {
        if (
          this.detailLeave == "" ||
          this.detailEmployeeId != this.currentEmployee.id
        ) {
          this.getDetailLeave();
        }
      }
      // set data for Profile
      this.firstName = this.currentEmployee.firstName;
      this.lastName = this.currentEmployee.lastName;
      this.gender = this.currentEmployee.gender;
      this.avatar = this.currentEmployee.avatar;
      this.email = this.currentEmployee.email;
      this.contactNumber = this.currentEmployee.contactNumber;
      this.active = this.currentEmployee.active;
      if (this.currentEmployee.employeeUserId)
        this.employeeUserId = this.currentEmployee.employeeUserId;
      // set data for Employment
      this.job = this.currentEmployee.job;
      if (
        this.currentEmployee.startDate != undefined &&
        this.currentEmployee.startDate != ""
      ) {
        this.startDate = this.getDate(this.currentEmployee.startDate);
        // this.startDateDay = this.currentEmployee.startDate.substring(8, 10)
        // this.startDateMonth = this.currentEmployee.startDate.substring(5, 7)
        // this.startDateYear = this.currentEmployee.startDate.substring(0, 4)
      }
      this.terminationDate = "";
      this.terminationAmount = 0;
      if (
        this.currentEmployee.terminationDate != undefined &&
        this.currentEmployee.terminationDate != ""
      ) {
        this.terminationDate = this.getDate(
          this.currentEmployee.terminationDate
        );
       this.terminationDefaultDisable = true; 
        this.CountTerminationAmount();
      } else {
         this.terminationDefaultDisable = false; 
      }
      this.employmentType = this.currentEmployee.employmentType;
      this.weeklyWorkHours = this.currentEmployee.weeklyWorkHours;
      this.leaveLoading = this.currentEmployee.leaveLoading;
      this.casualLoading = this.currentEmployee.casualLoading;
      this.payPeriod = this.currentEmployee.payPeriod;
      this.payRatePerHour = this.currentEmployee.payRatePerHour;
      this.payRate = this.currentEmployee.payRate;
      this.typeRate = this.currentEmployee.typeRate;

      if (this.active == "ACTIVE") {
        this.activeEmployee = true;
      } else {
        this.activeEmployee = false;
      }

      if (this.active == "PENDING") {
        this.address = "";
        this.address1 = "";
        this.address2 = "";
        this.suburb = "";
        this.postcode = "";
        this.state = null;
        this.countryCode = "au";
        this.country = Countries.getCountry(this.countryCode);

        // set data for Bank and Super
        this.paymentMethod = "Cash";
        this.bankAccountName = "";
        this.bankAccountBSB = "";
        this.bankAccountNumber = "";
        this.superFundType = "Regulated Superannuation Fund";
        this.listSupperFund = [];
        this.isShowListSupperFund = false;
        this.remoteSupperFund();
        this.contributionRate = 9.5;
        this.fundName = "";
        this.fundABN = "";
        this.fundAddress = "";
        this.fundUSI = "";
        this.fundEmployerNumber = "";
        this.memberNumber = "";
        this.fundBankName = "";
        this.fundBankBSB = "";
        this.fundBankNumber = "";

        // set data for Tax
        this.birthDate = "";
        // this.birthDateDay = ""
        // this.birthDateMonth = ""
        // this.birthDateYear = ""
        this.statusResidency = null;
        this.isExemption = false;
        this.tfnExemption = null;
        this.taxNumber = "";
        this.claimTaxFreeThreshold = false;
        this.hasHecsOrHelpDebt = false;
        // this.hasTslOrStudentStart = false;
        this.hasFinancialSupplementDebt = false;
        this.taxFileNumberDeclaration = false;
        this.hasTaxTemplate = false;
        this.taxTemplate = 0;

        // set data for Opening balances
        this.annualLeaveBalance = 0;
        this.sickLeaveBalance = 0;
        this.asAtBalance = "";
        this.grossEarningsBalance = 0;
        this.taxBalance = 0;
        this.superGuaranteeBalance = 0;
        this.employerContributionBalance = 0;
        this.FBT = 0;
        this.carAllowanceBalance = 0;
        this.transportAllowanceBalance = 0;
        this.laundryAllowanceBalance = 0;
        this.mealsAllowanceBalance = 0;
        this.travelAllowanceBalance = 0;

        this.unionFeesDeductionBalance = 0;
        this.workplaceGivingdeductionBalance = 0;

        // set data for Template
        this.extraHours = [];
        this.allowance = [];
        this.deduction = [];
        this.bonus = [];
        this.termination = [];
        // this.superGuarantee = this.currentEmployee.superGuarantee
        this.superSalarySacrifice = 0;
        this.employerContribution = 0;
        this.notes = "";
      } else {
        // set data for Profile
        this.address1 = this.currentEmployee.address1;
        this.address2 = this.currentEmployee.address2;
        this.suburb = this.currentEmployee.suburb;
        this.postcode = this.currentEmployee.postcode;
        this.state = this.currentEmployee.state;
        this.countryCode = this.currentEmployee.country;
        if (this.countryCode == "au") {
              if(this.address1 && this.suburb) {
                this.address =
                this.address1 +
                ", " +
                this.suburb +
                ", " +
                this.state;
              } else  {
                this.address = this.state
              }
        } else {
          this.address = this.address1 + ", " + this.suburb;
           if(this.address1 && this.suburb) {
                this.address =
                this.address1 +
                ", " +
                this.suburb
              } 
        }
        this.ogirinData.address = this.address;
        this.country = Countries.getCountry(this.countryCode);

        // set data for Bank and Super
        this.paymentMethod = this.currentEmployee.bank.paymentMethod;
        this.bankAccountName = this.currentEmployee.bank.bankAccountName;
        this.bankAccountBSB = this.currentEmployee.bank.bankAccountBSB;
        this.bankAccountNumber = this.currentEmployee.bank.bankAccountNumber;

        this.listSupperFund = [];
        this.isShowListSupperFund = false;
        this.superFundType = this.currentEmployee.fund.superFundType;
        this.remoteSupperFund();
        if (
          this.superFundType == "Regulated Superannuation Fund" &&
          (this.currentEmployee.fund.fundName != "" ||
            this.currentEmployee.fund.fundABN != "")
        ) {
          this.organisationName = this.currentEmployee.fund.fundName;
          this.identifierValue = this.currentEmployee.fund.fundABN;
          this.isShowDetailSupperFund = true;
        }

        this.contributionRate = this.currentEmployee.fund.contributionRate;
        this.fundName = this.currentEmployee.fund.fundName;
        this.fundABN = this.currentEmployee.fund.fundABN;
        this.fundAddress = this.currentEmployee.fund.fundAddress;
        this.fundUSI = this.currentEmployee.fund.fundUSI;
        this.fundEmployerNumber = this.currentEmployee.fund.fundEmployerNumber;
        this.memberNumber = this.currentEmployee.fund.memberNumber;
        this.fundBankName = this.currentEmployee.fund.fundBankName;
        this.fundBankBSB = this.currentEmployee.fund.fundBankBSB;
        this.fundBankNumber = this.currentEmployee.fund.fundBankNumber;

        // set data for Tax
        if (
          this.currentEmployee.birthDate != undefined &&
          this.currentEmployee.birthDate != ""
        ) {
          this.birthDate = this.getDate(this.currentEmployee.birthDate);
          // this.birthDateDay = this.currentEmployee.birthDate.substring(8, 10)
          // this.birthDateMonth = this.currentEmployee.birthDate.substring(5, 7)
          // this.birthDateYear = this.currentEmployee.birthDate.substring(0, 4)
        }
        this.statusResidency = this.currentEmployee.statusResidency;
        this.isExemption = this.currentEmployee.isExemption;
        this.tfnExemption = this.currentEmployee.tfnExemption;
        this.taxNumber = this.currentEmployee.taxNumber;
        this.claimTaxFreeThreshold = this.currentEmployee.claimTaxFreeThreshold;
        this.hasHecsOrHelpDebt = this.currentEmployee.hasHecsOrHelpDebt;
        // this.hasTslOrStudentStart = this.currentEmployee.hasTslOrStudentStart;
        this.hasFinancialSupplementDebt =
          this.currentEmployee.hasFinancialSupplementDebt;
        this.taxFileNumberDeclaration =
          this.currentEmployee.taxFileNumberDeclaration;
        this.hasTaxTemplate = this.currentEmployee.hasTaxTemplate;
        this.taxTemplate = this.currentEmployee.taxTemplate;

        // set data for Opening balances
        this.annualLeaveBalance = this.currentEmployee.annualLeaveBalance;
        this.sickLeaveBalance = this.currentEmployee.sickLeaveBalance;
        var now = new Date();
        if (this.getDate(this.currentEmployee.asAtBalance) === "1-01-01") {
          this.asAtBalance = this.getDate(now);
          this.ogirinData.asAtBalance = this.getDate(now);
        } else {
          this.asAtBalance = this.getDate(this.currentEmployee.asAtBalance);
        }
        this.grossEarningsBalance = this.currentEmployee.grossEarningsBalance;
        this.taxBalance = this.currentEmployee.taxBalance;
        this.superGuaranteeBalance = this.currentEmployee.superGuaranteeBalance;
        this.employerContributionBalance =
          this.currentEmployee.employerContributionBalance;
        this.carAllowanceBalance = this.currentEmployee.carAllowanceBalance;
        this.transportAllowanceBalance =
          this.currentEmployee.transportAllowanceBalance;
        this.laundryAllowanceBalance =
          this.currentEmployee.laundryAllowanceBalance;
        this.mealsAllowanceBalance = this.currentEmployee.mealsAllowanceBalance;
        this.travelAllowanceBalance =
          this.currentEmployee.travelAllowanceBalance;
        this.unionFeesDeductionBalance =
          this.currentEmployee.unionFeesDeductionBalance;
        this.workplaceGivingdeductionBalance =
          this.currentEmployee.workplaceGivingdeductionBalance;

        // set data for Template
        Logs.json('listExtraHours getting', this.currentEmployee.listExtraHours)
        this.extraHours = this.currentEmployee.listExtraHours;
        this.allowance = this.currentEmployee.listAllowance;
        this.allowance.forEach((allowance) => {
          if (allowance.type !== 'CAR' && allowance.type !== 'TRANSPORT' && allowance.type !== 'LAUNDRY' && allowance.type !== 'MEALS' && allowance.type !== 'TRAVEL') {
           let type= allowance.otherName;
            allowance.otherName = 'OTHER'
            allowance.type=type.replace(" ", '_').toUpperCase()
          }
        })

        this.deduction = this.currentEmployee.listDeduction;
        this.deduction.forEach((deduction) => {
          if (deduction.type !== 'WORKPLACE_GIVING' && deduction.type !== 'FEES') {
           let type= deduction.otherName;
            deduction.otherName = 'OTHER'
            deduction.type=type.replace(" ", '_').toUpperCase()
          }
        })
        this.bonus = [];
        this.termination = [];

        // this.superGuarantee = this.currentEmployee.superGuarantee
        this.superSalarySacrifice = this.currentEmployee.superSalarySacrifice;
        this.employerContribution = this.currentEmployee.employerContribution;
        this.FBT = this.currentEmployee.fbt;
        this.notes = this.currentEmployee.notes;
      }

      if (this.$refs && this.$refs.googlemap && this.$refs.googlemap.$refs) {
         this.$refs.googlemap.$refs.autocomplete.value = this.address;
         this.$refs.googlemap2.$refs.autocomplete.value = this.address2;
      }
     
      
      this.newDirtyForm();
    },
    terminationDateChange() {
      this.employmentTerminationDateMenu = false;
      this.getEmployeeLeaves();
    },
    getEmployeeLeaves() {
      if (this.currentEmployee.id) {
        //   var empName= Session.get("Username")
        //   var empId= Session.get("currentEmployeeId")
        this.$root.$refs.AppCyrus.start();
        this.$store
          .dispatch(
            GET_LIST_EMPLOYEE_LEAVE_EMPLOYEE_ONLY,
            this.currentEmployee.id
          )
          .then((response) => {
            this.$root.$refs.AppCyrus.finish();
            var resp = response;
            this.employeeSubmit = resp[0];
            this.terminationAmount = 0;
            //var payRate = this.reFormatMoney(this.payRate);
            if (this.employmentType != "Casual") {
              var LeaveBalance = this.projectedLeaveBalance();
              var hourlyRate = this.payRatePerHour; // payRatePerHour; //
              // var hoursInDay = this.weeklyWorkHours / 5; //Daily work hours in 5-day week
              //  var totalHours = hoursInDay * LeaveBalance;
              this.terminationAmount = (LeaveBalance * hourlyRate).toFixed(2);
              if(this.currentEmployee && this.currentEmployee.id) {
                this.initTerminationMethod(this.currentEmployee.id);
              }
            }
          })
          .catch(() => {
            this.$root.$refs.AppCyrus.fail();
          });
      }
    },
    async CountTerminationAmount() {
      await this.getEmployeeLeaves();
    },
    projectedLeaveBalance() {
      var leaveBalance = 0;
      var startDate = this.terminationDate;
      var endDate = this.terminationDate;
      // datePayroll < startDate && datePayroll < endDate
      if (
        this.compareTwoDates(this.employeeSubmit.endDatePayRoll, startDate) &&
        this.compareTwoDates(this.employeeSubmit.endDatePayRoll, endDate)
      ) {
        var totalDay = this.calculateDate(
          this.employeeSubmit.endDatePayRoll,
          endDate
        );
        leaveBalance =
          this.currentLeaveBalance() +
          ((totalDay * 4) / 52) * this.employeeSubmit.workHoursAtTime;
      }
      // datePayroll > startDate && datePayroll < endDate
      else if (
        this.compareTwoDates(startDate, this.employeeSubmit.endDatePayRoll) &&
        this.compareTwoDates(this.employeeSubmit.endDatePayRoll, endDate)
      ) {
        var totalDay2 = this.calculateDate(
          this.employeeSubmit.endDatePayRoll,
          endDate
        );
        leaveBalance =
          this.currentLeaveBalance() +
          ((totalDay2 * 4) / 52) * this.employeeSubmit.workHoursAtTime;
      }
      // datePayroll > startDate && datePayroll > endDate
      else {
        leaveBalance = 0;
      }
      return Number(Utils.roundNumberR1(leaveBalance));
    },
    currentLeaveBalance() {
      var currentLeave = 0;
      currentLeave =
        this.employeeSubmit.totalAnnualLeave -
        this.employeeSubmit.totalAnnualLeaveUsed;
      return Number(Utils.roundNumberR1(currentLeave));
    },
    compareTwoDates(startDate, endDate) {
      var date01 = new Date(startDate);
      var date02 = new Date(endDate);
      if (date01.valueOf() >= date02.valueOf()) {
        return false;
      }
      return true;
    },
    calculateDate(startDate, endDate) {
      var star = new Date(startDate);
      var end = new Date(endDate);
      var count = 0;
      var curDate = star;
      while (curDate <= end) {
        var dayOfWeek = curDate.getDay();
        if (!(dayOfWeek == 6 || dayOfWeek == 0)) {
          count++;
        }
        curDate.setDate(curDate.getDate() + 1);
      }
      return count;
    },
    compareCurrentDates(date) {
      var date01 = new Date();
      var date02 = new Date(date);
      if (date01.valueOf() >= date02.valueOf()) {
        return false;
      }
      return true;
    },
    // Tab
    handleClick(tab) {
      //event
      //Logs.json(tab.label, event);
      this.setStyleTab(tab.label);
    },

    setStyleTab(style) {
      this.styleTab = style;

      if (style == "Tax") {
        this.isClickTax = true;
        if (this.hasTaxTemplate) {
          this.disabled();
        } else {
          this.checkStatusResidency(this.statusResidency);
        }
      } else {
        this.isClickTax = false;
      }

      if (style == "Leave" || style == "Payslip") {
        if (
          this.detailLeave == "" ||
          this.detailEmployeeId != this.currentEmployee.id
        ) {
          this.getDetailLeave();
        }
      }
      this.checkEmail();
    },

    checkEmail() {
      if (this.email != this.currentEmployee.email) {
        if (Session.has("currenCompanyId")) {
          this.$root.$refs.AppCyrus.start();
          var modelRequest = {
            companyId: Session.get("currenCompanyId"),
            employeeId: 0,
            email: this.email,
          };
          this.$store
            .dispatch(CHECK_EMAIL, modelRequest)
            .then(() => {
              this.$root.$refs.AppCyrus.finish();
              this.checkContactNumber();
            })
            .catch(() => {
              this.$root.$refs.AppCyrus.fail();
            });
        }
      } else {
        this.checkContactNumber();
      }
    },

    checkContactNumber() {
      if (this.contactNumber != undefined) {
        var phone1 = this.contactNumber.replace(/[\s()]+/g, "");
        if (phone1 != this.currentEmployee.contactNumber) {
          if (Session.has("currenCompanyId")) {
            this.$root.$refs.AppCyrus.start();
            var modelRequest = {
              companyId: Session.get("currenCompanyId"),
              employeeId: 0,
              contactNumber: phone1,
            };
            this.$store
              .dispatch(CHECK_CONTACT_NUMBER, modelRequest)
              .then(() => {
                this.$root.$refs.AppCyrus.finish();
                this.checkTaxNumber();
              })
              .catch(() => {
                this.$root.$refs.AppCyrus.fail();
              });
          }
        } else {
          this.checkTaxNumber();
        }
      }
    },

    checkTaxNumber() {
      if (
        this.currentEmployee.active != "PENDING" &&
        this.taxNumber != this.currentEmployee.taxNumber
      ) {
        if (Session.has("currenCompanyId")) {
          this.$root.$refs.AppCyrus.start();
          var modelRequest = {
            companyId: Session.get("currenCompanyId"),
            employeeId: 0,
            taxNumber: this.taxNumber,
          };
          this.$store
            .dispatch(CHECK_TAX_NUMBER, modelRequest)
            .then(() => {
              this.$root.$refs.AppCyrus.finish();
            })
            .catch(() => {
              this.$root.$refs.AppCyrus.fail();
            });
        }
      }
    },

    // Tab Profile
    onChangeGender(event) {
      this.gender = event.target.value;
    },

    // Tab Bank and Super
    onChangeMethod(event) {
      this.paymentMethod = event.target.value;
    },

    // Tab Employment
    onEmploymentTypeChange() {
      this.weeklyWorkHours = this.currentEmployee.weeklyWorkHours;
      if (this.employmentType != "Casual") {
        this.casualLoading = 0;
        this.leaveLoading = 0;
        if (this.employmentType == "FullTime") {
          Logs.string("default", this.weeklyWorkHoursDefault);
          this.isLock = true;
          if (this.currentEmployee.employmentType != "FullTime") {
            this.weeklyWorkHours = this.weeklyWorkHoursDefault;
          }
        }
      } else {
        this.leaveLoading = 0;
        this.casualLoading = 25;
      }
      this.changeInfoEmployee("employmentType", this.employmentType);
    },

    getUnit(event) {
      this.typeRate = event.target.value;
    },

    //Tab Tax
    statusResidencyChange() {
      if (this.hasTaxTemplate) {
        this.disabled();
      } else {
        this.checkStatusResidency(this.statusResidency);
      }
      this.changeInfoEmployee("statusResidency", this.statusResidency);
    },

    checkStatusResidency(statusResidency) {
      if (statusResidency == "AustraliaResident") {
        document.getElementById("claimTaxFreeThreshold").disabled = false;
        document.getElementById("hasHecsOrHelpDebt").disabled = false;
        // document.getElementById("hasTslOrStudentStart").disabled = false;
      } else if (statusResidency == "ForeignResident") {
        document.getElementById("claimTaxFreeThreshold").checked = false;
        this.claimTaxFreeThreshold = false;

        document.getElementById("claimTaxFreeThreshold").disabled = true;
        document.getElementById("hasHecsOrHelpDebt").disabled = false;
        // document.getElementById("hasTslOrStudentStart").disabled = false;
      } else if (statusResidency == "WorkingHoliday") {
        document.getElementById("claimTaxFreeThreshold").checked = false;
        document.getElementById("hasHecsOrHelpDebt").checked = false;
        // document.getElementById("hasTslOrStudentStart").checked = false;
        this.claimTaxFreeThreshold = false;
        this.hasHecsOrHelpDebt = false;
        // this.hasTslOrStudentStart = false;

        document.getElementById("claimTaxFreeThreshold").disabled = true;
        document.getElementById("hasHecsOrHelpDebt").disabled = true;
        // document.getElementById("hasTslOrStudentStart").disabled = true;
      }
    },

    checkOverwriteTax() {
      if (this.hasTaxTemplate) {
        this.disabled();
        this.taxTemplate = 0;
      } else {
        this.checkStatusResidency(this.statusResidency);
      }
    },

    disabled() {
      document.getElementById("claimTaxFreeThreshold").checked = false;
      document.getElementById("hasHecsOrHelpDebt").checked = false;
      // document.getElementById("hasTslOrStudentStart").checked = false;
      this.claimTaxFreeThreshold = false;
      this.hasHecsOrHelpDebt = false;
      // this.hasTslOrStudentStart = false;

      document.getElementById("claimTaxFreeThreshold").disabled = true;
      document.getElementById("hasHecsOrHelpDebt").disabled = true;
      // document.getElementById("hasTslOrStudentStart").disabled = true;
    },

    onTypeExemptionChange(event) {
      this.tfnExemption = event.target.value;
    },

    clickTaxFileNumber() {
      if (this.isExemption) {
        this.isExemption = false;
        this.tfnExemption = null;
      } else {
        this.isExemption = true;
        this.tfnExemption = "NoTFNQuoted";
        this.taxNumber = "";
      }
    },

    // Tab Opening Balances
    getAddressData(addressData) {
      var street_number = "";
      if (addressData.street_number != undefined) {
        street_number = addressData.street_number + " ";
      }
      this.address1 = street_number + addressData.route;
      this.suburb = addressData.locality;
      this.postcode = addressData.postal_code;
      this.state = addressData.administrative_area_level_1;
      this.address = this.address1 + ", " + this.suburb + ", " + this.state;
      this.countryCode = "au";
      this.country = Countries.getCountry("AU");
      this.changeInfoEmployee("address", this.address);
    },

    getDate(inputDate) {
      var date = new Date(inputDate);
      var day = date.getDate();
      var mount = date.getMonth() + 1;
      var year = date.getFullYear();
      if (day.toString().length == 1) {
        day = "0" + day;
      }
      if (mount.toString().length == 1) {
        mount = "0" + mount;
      }
      return year + "-" + mount + "-" + day;
    },

    // Tab Template
    payRateUI() {
      var payRatePerHour = 0;
      var payRate = this.reFormatMoney(this.payRate);
      if (this.typeRate === "PerHour") {
        var rateh = payRate * (this.weeklyWorkHours * 52);
        if (this.casualLoading > 0) {
          payRatePerHour = rateh * 1 + (this.casualLoading * rateh) / 100;
        }
        payRatePerHour = rateh * 1;
      } else if (this.typeRate === "PerDay") {
        var rated = payRate / Session.get("workHours");
        if (this.casualLoading > 0) {
          rated = rated + (this.casualLoading * rated) / 100;
        }
        payRatePerHour = rated;
      } else if (this.typeRate === "PerWeek") {
        var perWeek = payRate / this.weeklyWorkHours;
        if (this.casualLoading > 0) {
          perWeek = perWeek + (this.casualLoading * perWeek) / 100;
        }
        payRatePerHour = perWeek;
      } else if (this.typeRate === "PerAnnum") {
        var ratey = payRate / (this.weeklyWorkHours * 52);
        if (this.casualLoading > 0) {
          ratey = ratey + (this.casualLoading * ratey) / 100;
        }
        payRatePerHour = ratey;
      }
      return Utils.roundNumber(payRatePerHour);
    },
    payRatePerHourUI() {
      var payRatePerHour = 0;
      var payRate = this.reFormatMoney(this.payRate);
      if (this.typeRate === "PerHour") {
        var rateh = payRate;
        if (this.casualLoading > 0) {
          payRatePerHour = rateh * 1 + (this.casualLoading * rateh) / 100;
        }
        payRatePerHour = rateh * 1;
      } else if (this.typeRate === "PerDay") {
        var rated = payRate / Session.get("workHours");
        if (this.casualLoading > 0) {
          rated = rated + (this.casualLoading * rated) / 100;
        }
        payRatePerHour = rated;
      } else if (this.typeRate === "PerWeek") {
        var perWeek = payRate / this.weeklyWorkHours;
        if (this.casualLoading > 0) {
          perWeek = perWeek + (this.casualLoading * perWeek) / 100;
        }
        payRatePerHour = perWeek;
      } else if (this.typeRate === "PerAnnum") {
        var ratey = payRate / (this.weeklyWorkHours * 52);
        if (this.casualLoading > 0) {
          ratey = ratey + (this.casualLoading * ratey) / 100;
        }
        payRatePerHour = ratey;
      }
      return Utils.roundNumber(payRatePerHour);
    },

    payRatePerHourServer() {
      var payRatePerHour = 0;
      var payRate = this.reFormatMoney(this.payRate);
      if (this.typeRate === "PerHour") {
        payRatePerHour = payRate * 1;
      } else if (this.typeRate === "PerDay") {
        payRatePerHour = payRate / Session.get("workHours");
      } else if (this.typeRate === "PerWeek") {
        payRatePerHour = payRate / Session.get("workHours");
      } else if (this.typeRate === "PerAnnum") {
        payRatePerHour = payRate / (this.weeklyWorkHours * 52);
      }
      return Number(payRatePerHour);
    },

    getWorkHourOrdinary() {
      var workHourOrdinary = 0;
      if (this.payPeriod === "All") {
        if (Session.has("payPeriod")) {
          if (Session.get("payPeriod") == "Weekly") {
            workHourOrdinary = this.weeklyWorkHours;
          } else if (Session.get("payPeriod") == "Fortnightly") {
            workHourOrdinary = this.weeklyWorkHours * 2;
          } else if (Session.get("payPeriod") == "Monthly") {
            workHourOrdinary = (this.weeklyWorkHours * 52) / 12;
          }
        } else {
          workHourOrdinary = this.weeklyWorkHours;
        }
      } else if (this.payPeriod === "Weekly") {
        workHourOrdinary = this.weeklyWorkHours;
      } else if (this.payPeriod === "Fortnightly") {
        workHourOrdinary = this.weeklyWorkHours * 2;
      } else if (this.payPeriod === "Monthly") {
        workHourOrdinary = (this.weeklyWorkHours * 52) / 12;
      }
      return Utils.roundNumber(workHourOrdinary);
    },

    addListPay(name) {
      var index = this.arrayChange.changes.indexOf(name);
      if (index == -1) {
        this.arrayChange.add[name] = 1;
        this.arrayChange.changes.push(name);
      } else {
        this.arrayChange.add[name]++;
      }
    },
    removeListPay(name, index) {
      if (this[name][index].isAdd) {
        i = this.arrayChange.changes.indexOf(name);
        this.arrayChange.add[name]--;
        if (this.arrayChange.add[name] == 0) {
          this.arrayChange.changes.splice(i, 1);
        }
      } else {
        var i = this.arrayChange.changes.indexOf(name);
        if (i == -1) {
          this.arrayChange.changes.push(name);
          this.arrayChange.remove.push(name);
        }
      }
    },

    addExtrahours() {
      this.isAddExtrahours = true;   
      this.extraHours.push({...JSON.parse(JSON.stringify(this.listOptionExtraHours[0])),option: "ORDINARY_HOURS"});
      this.addListPay("extraHours");
    },

    removeExtrahours(index) {
      this.removeListPay("extraHours", index);
      this.extraHours.splice(index, 1);
      if (this.extraHours.length == 0) {
        this.isAddExtrahours = false;
      }
    },

    addAllowance() {
      this.isAddAllowance = true;
      this.allowance.push({
        isAdd: true,
        id: 0,
        unit: 0,
        cost: 0,
        option: "SUBJECT_TO_TAX_AND_SUPER",
        type: this.listTypeAllowance[0].value,
        noteJobKeeper: "",
        noteStarted: "",
        noteFinished: "",
      });
      this.addListPay("allowance");
    },

    removeAllowance(index) {
      this.removeListPay("allowance", index);
      this.allowance.splice(index, 1);
      if (this.allowance.length == 0) {
        this.isAddAllowance = false;
      }
    },

    addDeduction() {
      this.isAddDeduction = true;
      this.deduction.push({
        isAdd: true,
        id: 0,
        option: "POST_TAX_DEDUCTION",
        type: this.listTypeDeduction[0].value,
        amountDeduction: 0,
      });
      this.addListPay("deduction");
    },

    removeDeduction(index) {
      this.removeListPay("deduction", index);
      this.deduction.splice(index, 1);
      if (this.deduction.length == 0) {
        this.isAddDeduction = false;
      }
    },

    formatDate(inputDate) {
      return Utils.formatDateToString(inputDate);
    },

    //Tab Leave
    getDetailLeave() {
      this.$root.$refs.AppCyrus.start();
      this.detailEmployeeId = this.currentEmployee.id;
      this.$store
        .dispatch(DETAIL_LEAVE, this.currentEmployee.id)
        .then((response) => {
          this.detailLeave = response;
          this.annualLeave =
            Math.round(
              (this.detailLeave.totalAnnualLeave -
                this.detailLeave.totalAnnualLeaveUsed) *
                100
            ) / 100;
          this.sickLeave =
            Math.round(
              (this.detailLeave.totalSickLeave -
                this.detailLeave.totalSickLeaveUsed) *
                100
            ) / 100;
          this.listScheduledLeaves = response.scheduledLeaves;
          this.listPayslips = response.payslips;

          this.$root.$refs.AppCyrus.finish();
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.fail();
        });
    },

    getDeduction(arrayDeduction) {
      var totalDed = 0;
      arrayDeduction.forEach((element) => {
        totalDed = totalDed + element.amountDeduction;
      });
      return totalDed;
    },
    sort(column, key, type) {
      if (key == "L") {
        if (this.sortKeyL != column) {
          this.reverseL = type;
          this.sortKeyL = column;
        } else {
          this.reverseL = !this.reverseL;
        }
        Utils.sortListJson(
          this.listScheduledLeaves,
          this.sortKeyL,
          this.reverseL
        );
      } else {
        if (this.sortKeyP != column) {
          this.reverseP = type;
          this.sortKeyP = column;
        } else {
          this.reverseP = !this.reverseP;
        }
        Utils.sortListJson(this.listPayslips, this.sortKeyP, this.reverseP);
      }
    },

    // Modal and Popup
    openModal() {
      this.$modal.show("modal-editemployee", {
        data: "data",
      });
    },

    showPopupConfirm() {
      var elem = window.$("#popupConfirm");
      elem.removeClass("hide-popup");
    },

    hidePopupConfirm() {
      var elem = window.$("#popupConfirm");
      elem.addClass("hide-popup");
    },

    showPopupDelete() {
       var elem = window.$("#popupDeleteemployee");
      elem.removeClass("hide-popup");
      var elem2 = window.$("#btnDeleteemployee");
      elem2.removeClass("disabled");
      elem2.removeAttr('disabled');
      var elem3 = window.$("#btnCancelDeleteemployee");
      elem3.removeClass("disabled");
      elem3.removeAttr('disabled');
    },

    showPopupChange() {
      var elem = window.$("#popupCheckChangeEmployee");
      elem.removeClass("hide-popup");
    },

    clickOk() {
      this.hidePopupConfirm();
      this.closeModal();
    },

    clickYes() {
      this.hidePopupDelete();
      this.closeModal();
    },

    hilight() {
      var elem = window.$(".ng-confirm-type-animated");
      if (elem.hasClass("ng-confirm-hilight")) {
        elem.removeClass("ng-confirm-hilight");
        elem.addClass("ng-confirm-hilight");
      } else {
        elem.addClass("ng-confirm-hilight");
        setTimeout(function () {
          elem.removeClass("ng-confirm-hilight");
        }, 500);
      }
    },

    renderAvatar(avatar) {
      if (avatar != "") {
        this.isShowAvatar = true;
        return ApiManager.getUrlDomain() + avatar;
      } else {
        this.isShowAvatar = false;
        return "";
      }
    },

    //===> Request API
    searchSupperFund() {
      this.isShowDetailSupperFund = false;
      this.$root.$refs.AppCyrus.start();
      var request = {
        keySearch: this.edtSearchSupperFund,
        page: "",
        lenght: "",
      };
      this.$store
        .dispatch(SEARCH_SUPERFUND, request)
        .then((response) => {
          this.listSupperFund = [];
          this.isShowListSupperFund = true;
          this.listSupperFundFull = response.listSuperFund;
          if (response.listSuperFund != undefined) {
            this.totalPage = response.listSuperFund.length;
          }
          if (this.listSupperFundFull.length < 10) {
            this.pageSize = this.listSupperFundFull.length;
          } else {
            this.pageSize = 10;
          }
          for (var i = 0; i < this.pageSize; i++) {
            this.listSupperFund.push(this.listSupperFundFull[i]);
          }
          this.$root.$refs.AppCyrus.finish();

          setTimeout(() => {
            var elmnt = document.getElementById("search_form");
            elmnt.scrollIntoView();
          }, 1000);
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.fail();
        });
    },

    handleCurrentChange(page) {
      this.currenPage = page;
      this.listSupperFund = [];
      var from = (this.currenPage - 1) * this.pageSize + 1;
      var to = 0;
      if (this.pageSize * this.currenPage <= this.totalPage) {
        to = this.pageSize * this.currenPage;
      } else {
        to = this.totalPage;
      }
      for (var i = from; i < to; i++) {
        this.listSupperFund.push(this.listSupperFundFull[i]);
      }
    },

    detailSupperFund(keyABN) {
      this.$root.$refs.AppCyrus.start();
      this.$store
        .dispatch(DETAIL_SUPERFUND, keyABN)
        .then((response) => {
          this.$root.$refs.AppCyrus.finish();
          this.isShowListSupperFund = false;
          this.isShowDetailSupperFund = true;

          this.organisationName = response.organisationName;
          this.identifierValue = response.identifierValue;
          this.fundName = response.organisationName;
          this.fundABN = response.identifierValue;
          this.fundAddress = response.addressLine1;
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.fail();
        });
    },

    remoteSupperFund() {
      this.isShowDetailSupperFund = false;
      this.organisationName = "";
      this.identifierValue = "";
      this.memberNumber = "";
      this.fundName = "";
      this.fundABN = "";
      this.fundAddress = "";
      this.edtSearchSupperFund = "";
    },

    async updateEmployee() {
      var isDuplicate = [];
      var isDuplicateAllowance =[];
      var isDuplicateDeduction =[];
      if (this.extraHours.length) {
        isDuplicate = Object.values(this.extraHours.reduce((c, v) => {
          let k = v.option + '-' + v.payslipName;
          c[k] = c[k] || [];
          c[k].push(v);
          return c;
        }, {})).reduce((c, v) => v.length > 1 ? c.concat(v) : c, []);

        
        var CustomSelectExtraHours = window.$(".CustomSelectExtraHours");
          if (isDuplicate.length >0) {
              CustomSelectExtraHours.addClass("is-invalid");
              this.isValidate = false;
          } else {
            CustomSelectExtraHours.removeClass("is-invalid");
            this.isValidate = true;
          }
         
         
      }

      if (this.allowance.length) {
        isDuplicateAllowance = Object.values(this.allowance.reduce((c, v) => {
          let k = v.option + '-' + v.type;
          c[k] = c[k] || [];
          c[k].push(v);
          return c;
        }, {})).reduce((c, v) => v.length > 1 ? c.concat(v) : c, []);

        
        var customTypeAllowance = window.$(".customTypeAllowance");
          if (isDuplicateAllowance.length >0) {
              customTypeAllowance.addClass("is-invalid");
              this.isValidate = false;
          } else {
            customTypeAllowance.removeClass("is-invalid");
            this.isValidate = true;
          }
         
      }

      if (this.deduction.length) {
        isDuplicateDeduction = Object.values(this.deduction.reduce((c, v) => {
          let k = v.option + '-' + v.type;
          c[k] = c[k] || [];
          c[k].push(v);
          return c;
        }, {})).reduce((c, v) => v.length > 1 ? c.concat(v) : c, []);

       
        var customTypeDeduction = window.$(".customTypeDeduction");
          if (isDuplicateDeduction.length >0) {
              customTypeDeduction.addClass("is-invalid");
              this.isValidate = false;
          } else {
            customTypeDeduction.removeClass("is-invalid");
            this.isValidate = true;
          }
        
      }

       if (isDuplicate.length>0 || isDuplicateAllowance.length>0 || isDuplicateDeduction.length > 0) {
          Utils.toastError("Type must be different");
          return false;
        }

     
      var checkActive = "";
      if (this.activeEmployee) {
        checkActive = "ACTIVE";
      } else {
        checkActive = "INACTIVE";
      }


      this.deduction.forEach((deduction) => {
        if (deduction.type !== 'WORKPLACE_GIVING' && deduction.type !== 'FEES') {
           let type= deduction.type;
            deduction.otherName = type
            deduction.type='OTHER'
        }
      })
      this.allowance.forEach((allowance) => {
          if (allowance.type !== 'CAR' && allowance.type !== 'TRANSPORT' && allowance.type !== 'LAUNDRY' && allowance.type !== 'MEALS' && allowance.type !== 'TRAVEL') {
           let type= allowance.type;
            allowance.otherName = type
            allowance.type='OTHER'
          }
      })
     
      var request = {
        id: this.currentEmployee.id,
        companyId: this.currentEmployee.companyId,
        active: checkActive,
        employeeUserId: this.employeeUserId,
        //******Profile******
        firstName: this.firstName,
        lastName: this.lastName,
        gender: this.gender,
        avatar: this.avatar,
        email: this.email,
        contactNumber:
          this.contactNumber != undefined
            ? this.contactNumber.replace(/[\s()]+/g, "")
            : "",
        address1: this.address1,
        address2: this.address2,
        suburb: this.suburb,
        postcode: this.postcode,
        state: this.state,
        country: this.countryCode,
        //******Bank and Super******
        //===> Bank
        bank: {
          paymentMethod: this.paymentMethod,
          bankAccountName: this.bankAccountName,
          bankAccountBSB: this.bankAccountBSB,
          bankAccountNumber: this.bankAccountNumber,
        },
        //===> Supper Fund
        fund: {
          superFundType: this.superFundType,
          contributionRate: this.contributionRate,
          fundName: this.fundName,
          fundABN: this.fundABN,
          fundAddress: this.fundAddress,
          fundUSI: this.fundUSI,
          fundEmployerNumber: this.fundEmployerNumber,
          memberNumber: this.memberNumber,
          fundBankName: this.fundBankName,
          fundBankBSB: this.fundBankBSB,
          fundBankNumber: this.fundBankNumber,
        },
        //******Employment******
        job: this.job,
        // "startDate": this.startDateYear + "-" + this.startDateMonth + "-" + this.startDateDay,
        startDate: this.startDate,
        terminationDate: this.terminationDate,
        employmentType: this.employmentType,
        weeklyWorkHours: this.weeklyWorkHours,
        leaveLoading: this.leaveLoading,
        casualLoading: this.casualLoading,
        payPeriod: this.payPeriod,
        payRatePerHour: this.payRatePerHourServer(),
        payRate: this.reFormatMoney(this.payRate),
        typeRate: this.typeRate,
        //******Tax******
        // "birthDate": this.birthDateYear + "-" + this.birthDateMonth + "-" + this.birthDateDay,
        birthDate: this.birthDate,
        statusResidency: this.statusResidency,
        isExemption: this.isExemption,
        tfnExemption: this.tfnExemption,
        taxNumber: this.taxNumber,
        claimTaxFreeThreshold: this.claimTaxFreeThreshold,
        hasHecsOrHelpDebt: this.hasHecsOrHelpDebt,
        // hasTslOrStudentStart: this.hasTslOrStudentStart,
        hasFinancialSupplementDebt: this.hasFinancialSupplementDebt,
        taxFileNumberDeclaration: this.taxFileNumberDeclaration,
        hasTaxTemplate: this.hasTaxTemplate,
        taxTemplate: this.taxTemplate,
        //******Opening balances******
        annualLeaveBalance: this.annualLeaveBalance,
        sickLeaveBalance: this.sickLeaveBalance,
        asAtBalance: this.asAtBalance,
        grossEarningsBalance: this.grossEarningsBalance,
        taxBalance: this.taxBalance,
        superGuaranteeBalance: this.superGuaranteeBalance,
        employerContributionBalance: this.employerContributionBalance,
        carAllowanceBalance: this.carAllowanceBalance,
        transportAllowanceBalance: this.transportAllowanceBalance,
        laundryAllowanceBalance: this.laundryAllowanceBalance,
        mealsAllowanceBalance: this.mealsAllowanceBalance,
        travelAllowanceBalance: this.travelAllowanceBalance,
        unionFeesDeductionBalance: this.unionFeesDeductionBalance,
        workplaceGivingdeductionBalance: this.workplaceGivingdeductionBalance,
        listExtraHours: this.extraHours,
        listAllowance: this.allowance,
        listDeduction: this.deduction,
        listBonous: this.bonus,
        listTermination: this.termination,
        superGuarantee: this.superGuarantee,
        superSalarySacrifice: this.superSalarySacrifice,
        employerContribution: this.employerContribution,
        fbt: this.FBT,
        notes: this.notes,
      };
      this.checkValidate();
      if (this.isClickTax) {
        if (this.isValidate) {
          this.$root.$refs.AppCyrus.start();
          await this.$store
            .dispatch(UPDATE_EMPLOYEE, request)
            .then(() => {
              this.$root.$refs.AppCyrus.finish();
              if (this.$root.$refs.People != undefined) {
                this.$root.$refs.People.reloadDataEmployee(true);
              }
              this.arrayChange = {
                add: {},
                remove: [],
                changes: [],
              };
              Utils.toastSuccess("Update employee successful!");
            })
            .catch(() => {
              this.$root.$refs.AppCyrus.fail();
            });
        }
      } else {
        if (this.isValidate) {
          this.$root.$refs.AppCyrus.start();
          await this.$store
            .dispatch(UPDATE_EMPLOYEE, request)
            .then(() => {
              this.$root.$refs.AppCyrus.finish();
              if (this.$root.$refs.People != undefined) {
                this.$root.$refs.People.reloadDataEmployee(true);
              }
              this.arrayChange = {
                add: {},
                remove: [],
                changes: [],
              };
              Utils.toastSuccess("Update employee successful!");
              this.getDetailLeave()
            })
            .catch(() => {
              this.$root.$refs.AppCyrus.fail();
            });
        }
      }
    },
    checkValidate() {
      this.isValidate = true;
      window.$(".is-invalid").removeClass("is-invalid");
      // profile
      var firstName = window.$(".ng-first-name");
      if (Validate.isEmpty(this.firstName)) {
        firstName.addClass("is-invalid");
        window.$(".Profile").addClass("is-invalid");
        this.isValidate = false;
      } else {
        firstName.removeClass("is-invalid");
      }
      var lastName = window.$(".ng-last-name");
      if (Validate.isEmpty(this.lastName)) {
        lastName.addClass("is-invalid");
        window.$(".Profile").addClass("is-invalid");
        this.isValidate = false;
      } else {
        lastName.removeClass("is-invalid");
      }
      // var email = window.$(".ng-email");
      // if (!Validate.isEmail(this.email) || Validate.isEmpty(this.email)) {
      //   email.addClass("is-invalid");
      //   window.$(".Profile").addClass("is-invalid");
      //   this.isValidate = false;
      // } else {
      //   email.removeClass("is-invalid");
      // }
      var phone = window.$(".ng-phone");
      var contactPhone =
        this.contactNumber != undefined
          ? this.contactNumber.replace(/[\s()]+/g, "")
          : "";
      if (Validate.isPhone(contactPhone)) {
        phone.removeClass("is-invalid");
      } else {
        phone.addClass("is-invalid");
        window.$(".Profile").addClass("is-invalid");
        this.isValidate = false;
      }
      if (this.isCheckFindMyAddress) {
        var address = window.$(".ng-address1");
        if (Validate.isEmpty(this.address1)) {
          address.addClass("is-invalid");
          window.$(".Profile").addClass("is-invalid");
          this.isValidate = false;
        } else {
          address.removeClass("is-invalid");
        }
        var suburb = window.$(".ng-suburb");
        if (Validate.isEmpty(this.suburb)) {
          suburb.addClass("is-invalid");
          window.$(".Profile").addClass("is-invalid");
          this.isValidate = false;
        } else {
          suburb.removeClass("is-invalid");
        }
        var post = window.$(".ng-post-code");
        if (
          Validate.isEmpty(this.postcode) ||
          Validate.isNumber(this.postcode)
        ) {
          post.addClass("is-invalid");
          window.$(".Profile").addClass("is-invalid");
          this.isValidate = false;
        } else {
          post.removeClass("is-invalid");
        }
        var state = window.$(".ng-state");
        if (Validate.isEmpty(this.state)) {
          state.addClass("is-invalid");
          window.$(".Profile").addClass("is-invalid");
          this.isValidate = false;
        } else {
          state.removeClass("is-invalid");
        }
      } else {
        address = window.$(".ng-address");
        if (Validate.isEmpty(this.address)) {
          address.addClass("is-invalid");
          window.$(".Profile").addClass("is-invalid");
          this.isValidate = false;
        } else {
          address.removeClass("is-invalid");
        }
      }

      // Bank
      if (this.paymentMethod === "Bank") {
        var accName = window.$(".ng-account-name");
        if (Validate.isEmpty(this.bankAccountName)) {
          accName.addClass("is-invalid");
          window.$(".Bank").addClass("is-invalid");
          this.isValidate = false;
        } else {
          accName.removeClass("is-invalid");
        }
        var bsb = window.$(".ng-bsb");
        if (
          Validate.isEmpty(this.bankAccountBSB) ||
          Validate.isNumber(this.bankAccountBSB)
        ) {
          bsb.addClass("is-invalid");
          window.$(".Bank").addClass("is-invalid");
          this.isValidate = false;
        } else {
          bsb.removeClass("is-invalid");
        }
        var accNumber = window.$(".ng-account-number");
        if (
          Validate.isEmpty(this.bankAccountNumber) ||
          Validate.isNumber(this.bankAccountNumber) ||
          this.bankAccountNumber.length < 6
        ) {
          accNumber.addClass("is-invalid");
          window.$(".Bank").addClass("is-invalid");
          this.isValidate = false;
        } else {
          accNumber.removeClass("is-invalid");
        }
      }
      // super
      var ConRate = window.$(".ng-Contribution-Rate");
      if (
        Validate.isEmpty(this.contributionRate) ||
        Validate.isNumber(this.contributionRate)
      ) {
        ConRate.addClass("is-invalid");
        window.$(".Super").addClass("is-invalid");
        this.isValidate = false;
      } else {
        ConRate.removeClass("is-invalid");
      }
      if (this.superFundType === "Self Managed Superannuation Fund") {
        var sfName = window.$(".ng-Sf-Name");
        var sfABN = window.$(".ng-Sf-ABN");
        var sfaccName = window.$(".ng-Sf-Account-Name");
        var sfBSB = window.$(".ng-Sf-BSB");
        var sfACCNumber = window.$(".ng-Sf-Account-Number");
        if (Validate.isEmpty(this.fundName)) {
          sfName.addClass("is-invalid");
          window.$(".Super").addClass("is-invalid");
          this.isValidate = false;
        } else {
          sfName.removeClass("is-invalid");
        }
        if (Validate.isEmpty(this.fundABN)) {
          sfABN.addClass("is-invalid");
          window.$(".Super").addClass("is-invalid");
          this.isValidate = false;
        } else {
          sfABN.removeClass("is-invalid");
        }
        if (Validate.isEmpty(this.fundBankName)) {
          sfaccName.addClass("is-invalid");
          window.$(".Super").addClass("is-invalid");
          this.isValidate = false;
        } else {
          sfaccName.removeClass("is-invalid");
        }
        if (
          Validate.isEmpty(this.fundBankBSB) ||
          Validate.isNumber(this.fundBankBSB)
        ) {
          sfBSB.addClass("is-invalid");
          window.$(".Super").addClass("is-invalid");
          this.isValidate = false;
        } else {
          sfBSB.removeClass("is-invalid");
        }
        if (
          Validate.isEmpty(this.fundBankNumber) ||
          Validate.isNumber(this.fundBankNumber) ||
          this.fundBankNumber.length < 6
        ) {
          sfACCNumber.addClass("is-invalid");
          window.$(".Super").addClass("is-invalid");
          this.isValidate = false;
        } else {
          sfACCNumber.removeClass("is-invalid");
        }
      } else if (this.isShowDetailSupperFund) {
        var number = window.$(".ng-number");
        if (Validate.isEmpty(this.memberNumber)) {
          number.addClass("is-invalid");
          window.$(".Super").addClass("is-invalid");
          this.isValidate = false;
        } else {
          number.removeClass("is-invalid");
        }
      }
      // employment
      var job = window.$(".ng-job-title");
      if (Validate.isEmpty(this.job)) {
        job.addClass("is-invalid");
        window.$(".Employment").addClass("is-invalid");
        this.isValidate = false;
      } else {
        job.removeClass("is-invalid");
      }
      var payRate = window.$(".ng-pay-rate");
      if (Validate.isEmpty(this.payRate)) {
        payRate.addClass("is-invalid");
        window.$(".Employment").addClass("is-invalid");
        this.isValidate = false;
      } else {
        payRate.removeClass("is-invalid");
      }
      var weeklyWorkHours = window.$(".ng-weekly-work-hours");
      if (Validate.isEmpty(this.weeklyWorkHours)) {
        weeklyWorkHours.addClass("is-invalid");
        window.$(".Employment").addClass("is-invalid");
        this.isValidate = false;
      } else {
        weeklyWorkHours.removeClass("is-invalid");
      }
      var employment = window.$(".ng-employment");
      if (Validate.isEmpty(this.employmentType)) {
        employment.addClass("is-invalid");
        window.$(".Employment").addClass("is-invalid");
        this.isValidate = false;
      } else {
        employment.removeClass("is-invalid");
      }
      // var startDay = window.$('.ng-start-day')
      // var startMonth = window.$('.ng-start-month')
      // var startYear = window.$('.ng-start-year')
      var startDate = window.$("ng-start-date");
      if (Validate.isEmpty(this.startDate)) {
        startDate.addClass("is-invalid");
        window.$(".Employment").addClass("is-invalid");
        this.isValidate = false;
      } else {
        startDate.removeClass("is-invalid");
      }

      var birthDate = window.$(".ng-birth-date");
      if (Validate.isEmpty(this.birthDate)) {
        birthDate.addClass("is-invalid");
        window.$(".Tax").addClass("is-invalid");
        this.isValidate = false;
      } else {
        birthDate.removeClass("is-invalid");
      }
      var tax = window.$(".ng-tax-file-number");
      var status = window.$(".ng-Status-Residency");

      var checkTax = false;
      var checkTaxEmpty = false;
      if (!this.isExemption) {
        checkTax = Validate.CheckTFN(this.taxNumber);
        checkTaxEmpty = Validate.isEmpty(this.taxNumber);
      } else {
        checkTax = false;
        checkTaxEmpty = false;
      }

      //Check Tax File Number
      if (checkTaxEmpty || checkTax) {
        tax.addClass("is-invalid");
        window.$(".Tax").addClass("is-invalid");
        this.isValidate = false;
      } else {
        tax.removeClass("is-invalid");
      }
      //Check StatusResidency
      if (Validate.isEmpty(this.statusResidency)) {
        status.addClass("is-invalid");
        window.$(".Tax").addClass("is-invalid");
        this.isValidate = false;
      } else {
        status.removeClass("is-invalid");
      }
      var taxTemplate = window.$(".ng-taxTemplate");
      if (
        this.hasTaxTemplate &&
        (this.taxTemplate == 0 || Validate.isEmpty(this.taxTemplate))
      ) {
        taxTemplate.addClass("is-invalid");
        window.$(".Tax").addClass("is-invalid");
        this.isValidate = false;
      } else {
        taxTemplate.removeClass("is-invalid");
      }

      // Opening balances
      var asAt = window.$(".ng-time1");
      if (Validate.isEmpty(this.asAtBalance)) {
        asAt.addClass("is-invalid");
        window.$(".Opening").addClass("is-invalid");
        this.isValidate = false;
      } else {
        asAt.removeClass("is-invalid");
      }
    },

    formatPhoneNumber() {
      if (this.contactNumber != undefined) {
        var x = this.contactNumber
          .replace(/[^+\d]/g, "")
          .match(/(\+\d{0,5}|\d{0,4})(\d{0,3})(\d{0,3})/);
        if (x[1].indexOf("+") == 0) {
          this.contactNumber = !x[2]
            ? "(" + x[1].slice(0, 3) + ") " + x[1].slice(3)
            : "(" +
              x[1].slice(0, 3) +
              ") " +
              x[1].slice(3) +
              " " +
              x[2] +
              (x[3] ? " " + x[3] : "");
        } else {
          this.contactNumber = !x[2]
            ? x[1]
            : "" + x[1] + " " + x[2] + (x[3] ? " " + x[3] : "");
        }
      }
    },
    reFormatMoney(val) {
      val = val + "";
      return val.replace(/,/g, "");
    },
    formatMoney(val) {
      val = val + "";
      if (Number(val) == 0) {
        return 0;
      } else {
        val = val.replace(/^0*/, "");
      }
      var x = val.replace(/[^,\d]/g, "").match(/\d/g);
      var money = "";
      var count = 0;
      for (var i = x.length - 1; i >= 0; i--) {
        count++;
        if (count == 4) {
          // money = x[i] + "," + money;
          money = x[i] + money;
          count = 1;
        } else {
          money = x[i] + money;
        }
      }
      return money;
    },

    async getPayslipEmployee(payrollDetailId) {
      // construct the url
      const baseUrl = ApiManager.getUrlApi();
      const link = `${baseUrl}/api/PaySlip/PayslipForEmployee?payrollDetailId=${payrollDetailId}`

      // make the GET request
      const response = await axios.get(link, { 
        headers: {
            'Authorization': "Bearer " + Token.getToken(),
            'handshake': 'Basic ' + Token.getCodeHandShake()
        },
        responseType: 'arraybuffer' 
      });

      // create a new blob with content of the response data
      const file = new Blob([response.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);

      // show the pdf
      window.open(fileURL, '_blank');
    },

    viewPaySlipPdf(link) {
      this.$modal.show("modal-viewPaySlip-pdf", {
        link: link,
      });
    },

    viewPaySlipData(dataPdf, titleName) {
      this.$modal.hide("modal-payrundetails");
      this.$modal.show("modal-viewPaySlip-data", {
        link: dataPdf,
        titleName: titleName,
      });
    },
  },
  watch: {
    contactNumber: function (val) {
      if (val != undefined) {
        var x = val
          .replace(/[^+\d]/g, "")
          .match(/(\+\d{0,5}|\d{0,4})(\d{0,3})(\d{0,3})/);
        if (x[1].indexOf("+") == 0) {
          this.contactNumber = !x[2]
            ? "(" + x[1].slice(0, 3) + ") " + x[1].slice(3)
            : "(" +
              x[1].slice(0, 3) +
              ") " +
              x[1].slice(3) +
              " " +
              x[2] +
              (x[3] ? " " + x[3] : "");
        } else {
          this.contactNumber = !x[2]
            ? x[1]
            : "" + x[1] + " " + x[2] + (x[3] ? " " + x[3] : "");
        }
      }
    },
    payRate: function (val) {
      this.payRate = this.formatMoney(val);
    },
  },

  computed: {
    computedStartDateFormatted() {
      return this.startDate ? moment(this.startDate).format("DD/MM/YYYY") : "";
    },
    computedTerminationDateFormatted() {
      return this.terminationDate
        ? moment(this.terminationDate).format("DD/MM/YYYY")
        : "";
    },

    computedDateOfBirthDateFormatted() {
      return this.birthDate ? moment(this.birthDate).format("DD/MM/YYYY") : "";
    },

    computedAsAtBalanceDateFormatted() {
      return this.asAtBalance
        ? moment(this.asAtBalance).format("DD/MM/YYYY")
        : "";
    },
  },
};
</script>

<style scoped>
@import url("/assets/css/setting.css");
@import url("//unpkg.com/element-ui@2.13.1/lib/theme-chalk/index.css");


</style>

<style>
.v-select__selections {
  flex-wrap: inherit !important;
}

div.app div.content-people span.el-tabs__nav-next, 
div.app div.content-people span.el-tabs__nav-prev {
    font-size: 30px;
    top: 4px;
}
</style>
